import { createContext, useState, useEffect } from "react";
import { useEquipmentCategoryApi } from "../../../api/equipmentCategoryApi";
import { useInspectionTypeApi } from "../../../api/inspectionTypeApi";

const emptyInspectionType = {
    id: null,
    name: null,
    category: null,
    default: false,
};

const InspectionTypeContext = createContext({});

export const InspectionTypeProvider = ({ children }) => {
    const { getInspectionTypes, saveInspectionType, deleteInspectionType } =
        useInspectionTypeApi();
    const { getEquipmentCategories } = useEquipmentCategoryApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [inspectionTypes, setInspectionTypes] = useState([]);
    const [equipmentCategories, setEquipmentCategories] = useState([]);
    const [data, setData] = useState({
        emptyInspectionType,
    });
    const hasError = !data.name;
    const showTableEditButtons = data.id != null;

    function processData(name, value) {
        switch (name) {
            case "name": {
                break;
            }
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        processData(e.target.name, value);

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchInspectionTypes();
        fetchEquipmentCategories();
    }, []);

    function setSelectedInspectionType(id) {
        if (id != null) {
            const inspectionType = inspectionTypes.find(
                (inspectionType) => inspectionType.id === id
            );
            setData(inspectionType);
        } else {
            setData(emptyInspectionType);
        }
    }

    function fetchInspectionTypes() {
        getInspectionTypes((response) => {
            setInspectionTypes(response.data.inspectionTypes);
        });
    }

    function fetchEquipmentCategories() {
        getEquipmentCategories((response) => {
            setEquipmentCategories(response.data.equipmentCategories);
        });
    }

    function save() {
        saveInspectionType(data, (response) => {
            setData(emptyInspectionType);
            fetchInspectionTypes();
        });
    }

    function deleteInspType() {
        deleteInspectionType(data.id, (response) => {
            fetchInspectionTypes();
        });
    }

    return (
        <InspectionTypeContext.Provider
            value={{
                data,
                equipmentCategories,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                inspectionTypes,
                setInspectionTypes,
                hasError,
                handleChange,
                save,
                deleteInspType,
                showTableEditButtons,
                setSelectedInspectionType,
            }}
        >
            {children}
        </InspectionTypeContext.Provider>
    );
};

export default InspectionTypeContext;
