import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog(props) {
    const [showAlert, setShowAlert] = props.open;
    const title = props.title;
    const message = props.message;
    const positiveButton = props.positiveButton;
    const negativeButton = props.negativeButton;
    const buttonHandler = props.buttonHandler;

    const handlePositiveButton = () => {
        buttonHandler(true);
        handleClose();
    };

    const handleNegativeButton = () => {
        buttonHandler(false);
        handleClose();
    };

    const handleClose = () => {
        setShowAlert(false);
    };

    return (
        <div>
            <Dialog
                open={showAlert}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title && (
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                )}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNegativeButton}>
                        {negativeButton}
                    </Button>
                    <Button onClick={handlePositiveButton} autoFocus>
                        {positiveButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
