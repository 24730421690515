import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { EQUIPMENT_TYPE_ATTRIBUTE_URL } from "../../../api/Url";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useFullScreenProgressBarContext from "../../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../../common/generalerrorsnackbar/useGeneralErrorContext";
import { useEquipmentTypeApi } from "../../../api/equipmentTypeApi";

const emptyEquipmentTypeAttribute = {
    id: null,
    name: null,
    unit: null,
    type: null,
};

const EquipmentTypeAttributeContext = createContext({});

export const EquipmentTypeAttributeProvider = ({ children }) => {
    const { getEquipmentTypes } = useEquipmentTypeApi();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showTableLoading, setShowTableLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [editedId, setEditedId] = useState(null);

    const [equipmentTypes, setEquipmentTypes] = useState([]);
    const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);

    const [equipmentTypeAttributes, setEquipmentTypeAttributes] = useState([]);

    const [equipmentTypeAttribute, setEquipmentTypeAttribute] = useState({
        emptyEquipmentTypeAttribute,
    });

    const hasError =
        equipmentTypeAttribute.name == null ||
        equipmentTypeAttribute.unit == null ||
        equipmentTypeAttribute.type == null;

    function processData(name, value) {
        switch (name) {
            case "name": {
                break;
            }
            case "type":
                setSelectedEquipmentType(value);
                break;
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        processData(e.target.name, value);

        setEquipmentTypeAttribute((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        getEquipmentTypes((response) => {
            setEquipmentTypes(response.data.equipmentTypes);
        });
    }, []);

    useLayoutEffect(() => {
        if (editedId != null) {
            const equipmentTypesAttribute = equipmentTypeAttributes.find(
                (equipmentTypesAttribute) =>
                    equipmentTypesAttribute.id === editedId
            );
            setEquipmentTypeAttribute(equipmentTypesAttribute);
        } else {
            setEquipmentTypeAttribute(emptyEquipmentTypeAttribute);
        }
    }, [editedId, setEditedId]);

    useEffect(() => {
        if (selectedEquipmentType != null) {
            fetchEquipmentTypeAttributesByType();
        } else {
            setEquipmentTypeAttributes([]);
        }
    }, [selectedEquipmentType, setSelectedEquipmentType]);

    const fetchEquipmentTypeAttributesByType = async (e) => {
        setShowTableLoading(true);
        try {
            const response = await axiosPrivate.get(
                `${EQUIPMENT_TYPE_ATTRIBUTE_URL}/list/${selectedEquipmentType.id}`,
                {
                    withCredentials: true,
                }
            );
            setEquipmentTypeAttributes(response.data.equipmentTypeAttributes);
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowTableLoading(false);
        }
    };

    const saveEquipmentTypeAttribute = async () => {
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                id: equipmentTypeAttribute.id,
                name: equipmentTypeAttribute.name,
                unit: equipmentTypeAttribute.unit,
                type: equipmentTypeAttribute.type.id,
            };
            if (request.id === null) {
                await axiosPrivate.post(
                    `${EQUIPMENT_TYPE_ATTRIBUTE_URL}`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
            } else {
                await axiosPrivate.put(
                    `${EQUIPMENT_TYPE_ATTRIBUTE_URL}/${request.id}`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
            }
            setEquipmentTypeAttribute(emptyEquipmentTypeAttribute);
            fetchEquipmentTypeAttributesByType();
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    };

    const deleteEquipmentTypeAttribute = async () => {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate.delete(
                `${EQUIPMENT_TYPE_ATTRIBUTE_URL}/${equipmentTypeAttribute.id}`,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchEquipmentTypeAttributesByType();
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    };

    const showTableEditButtons = editedId === null ? false : true;

    return (
        <EquipmentTypeAttributeContext.Provider
            value={{
                equipmentTypes,
                equipmentTypeAttributes,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                editedId,
                setEditedId,
                hasError,
                handleChange,
                selectedEquipmentType,
                equipmentTypeAttribute,
                saveEquipmentTypeAttribute,
                deleteEquipmentTypeAttribute,
                showTableEditButtons,
                showTableLoading,
            }}
        >
            {children}
        </EquipmentTypeAttributeContext.Provider>
    );
};

export default EquipmentTypeAttributeContext;
