import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useStatisticsApi } from "../../api/statisticsApi";
import { useEffect, useState } from "react";
import UserPerformanceCard from "./UserPerformanceCard";
import InfoDisplay from "./InfoCard";
import styled from "@emotion/styled";

const SectionHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2), // assuming the theme's spacing unit is 8px
    marginBottom: theme.spacing(2),
    textAlign: "center", // if you want to center the text
}));

const StatisticsCard = () => {
    const { getDashboardStatistics } = useStatisticsApi();
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {
        fetchDashboardStatistics();
    }, []);

    function fetchDashboardStatistics() {
        getDashboardStatistics((response) => {
            setStatistics(response.data.statistics);
        });
    }

    function getUserPerformanceList(userPerformance) {
        return [
            {
                label: "Jegyzőkönyvek a héten",
                value: userPerformance.reportsThisWeek,
            },
            {
                label: "Jegyzőkönyvek a hónapban",
                value: userPerformance.reportsThisMonth,
            },
            {
                label: "Jegyzőkönyvezett eszközök a héten",
                value: userPerformance.reportEquipmentsThisWeek,
            },
            {
                label: "Jegyzőkönyvezett eszközök a hónapban",
                value: userPerformance.reportEquipmentsThisMonth,
            },
        ];
    }
    return (
        <Box>
            <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                    backgroundColor: "primary.main", // using the theme's primary color
                    color: "primary.contrastText", // ensuring text is readable on the primary color
                    padding: "8px", // adding some padding
                    borderRadius: "4px", // optional rounded corners
                }}
            >
                Statisztika
            </Typography>
            {statistics != null ? (
                <Box>
                    <SectionHeader>
                        Személyes Teljesítmény Áttekintés
                    </SectionHeader>
                    <Box>
                        <Grid
                            container
                            spacing={3}
                            rowSpacing={3}
                            alignItems="stretch"
                        >
                            <Grid item xs={6}>
                                <InfoDisplay
                                    name="Jegyzőkönyvek a héten"
                                    value={
                                        statistics.currentUserStatistics
                                            .reportsThisWeek
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoDisplay
                                    name="Jegyzőkönyvek a hónapban"
                                    value={
                                        statistics.currentUserStatistics
                                            .reportsThisMonth
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoDisplay
                                    name="Jegyzőkönyvezett eszközök a héten"
                                    value={
                                        statistics.currentUserStatistics
                                            .reportEquipmentsThisWeek
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoDisplay
                                    name="Jegyzőkönyvezett eszközök a hónapban"
                                    value={
                                        statistics.currentUserStatistics
                                            .reportEquipmentsThisMonth
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <SectionHeader>
                            Adminisztratív Statisztikák
                        </SectionHeader>

                        <Grid
                            container
                            spacing={3}
                            rowSpacing={3}
                            alignItems="stretch"
                        >
                            <Grid key="mostReportInWeekFullName" item xs={6}>
                                <InfoDisplay
                                    name="Legtöbb jegyzőkönyv a héten"
                                    value={
                                        statistics.publicStatistics
                                            .mostReportInWeekFullName
                                    }
                                />
                            </Grid>
                            <Grid key="mostReportInMonthFullName" item xs={6}>
                                <InfoDisplay
                                    name="Legtöbb jegyzőkönyv a hónapban"
                                    value={
                                        statistics.publicStatistics
                                            .mostReportInMonthFullName
                                    }
                                />
                            </Grid>
                        </Grid>

                        {statistics.adminStatistics && (
                            <Grid
                                sx={{ mt: 0 }}
                                container
                                spacing={3}
                                rowSpacing={3}
                                alignItems="stretch"
                            >
                                <Grid key="reportsThisWeek" item xs={4}>
                                    <InfoDisplay
                                        name="Összes jegyzőkönyv a héten"
                                        value={
                                            statistics.adminStatistics
                                                .reportsThisWeek
                                        }
                                    />
                                </Grid>
                                <Grid key="reportsThisMonth" item xs={4}>
                                    <InfoDisplay
                                        name="Összes jegyzőkönyv a hónapban"
                                        value={
                                            statistics.adminStatistics
                                                .reportsThisMonth
                                        }
                                    />
                                </Grid>
                                <Grid key="reportsTotal" item xs={4}>
                                    <InfoDisplay
                                        name="Összes jegyzőkönyv"
                                        value={
                                            statistics.adminStatistics
                                                .reportsTotal
                                        }
                                    />
                                </Grid>
                                {statistics.adminStatistics.userPerformances.map(
                                    (userPerformance) => (
                                        <Grid
                                            key={userPerformance.email}
                                            item
                                            xs={6}
                                        >
                                            <UserPerformanceCard
                                                name={userPerformance.fullName}
                                                list={getUserPerformanceList(
                                                    userPerformance
                                                )}
                                            />
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default StatisticsCard;
