import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useLocationContext from "./useLocationContext";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.2 },
    { field: "city", headerName: "Város", minWidth: 130, flex: 0.2 },
    { field: "zip", headerName: "Ir.szám", minWidth: 130, flex: 0.2 },
    { field: "address", headerName: "Cím", minWidth: 130, flex: 0.2 },
    {
        field: "operator",
        headerName: "Üzemeltető",
        minWidth: 130,
        flex: 0.2,
        valueGetter: (params) => params.row.operator.name,
    },
    {
        field: "contactPerson",
        headerName: "Kapcsolattartó",
        minWidth: 130,
        flex: 0.2,
    },
];

function LocationTable() {
    const { locations, setSelectedLocation } = useLocationContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedLocation(id);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={locations}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default LocationTable;
