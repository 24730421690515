import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";

// Styled item value which is bold and primary color
const ItemValue = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "inline",
    variant: "h6",
    style: { flexShrink: 0, whiteSpace: "nowrap" },
}));

// A functional component that takes name and value as props
const InfoDisplay = ({ name, value }) => {
    return (
        <Paper
            elevation={3}
            style={{
                padding: "16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Typography
                variant="body2"
                style={{ flexShrink: 1, marginRight: "8px" }}
            >
                {name}
            </Typography>
            <ItemValue>{value}</ItemValue>
        </Paper>
    );
};

export default InfoDisplay;
