import { EquipmentProvider } from "./EquipmentContext";
import EquipmentPageContent from "./EquipmentPageContent";

function EquipmentPage() {
    return (
        <EquipmentProvider>
            <EquipmentPageContent />
        </EquipmentProvider>
    );
}

export default EquipmentPage;
