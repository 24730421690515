import { AdminTemplateProvider } from "./AdminTemplateContext";
import AdminTemplatePageContent from "./AdminTemplatePageContent";

function AdminTemplatePage() {
    return (
        <AdminTemplateProvider>
            <AdminTemplatePageContent />
        </AdminTemplateProvider>
    );
}

export default AdminTemplatePage;
