import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useEquipmentTypeRestrictionContext from "./useEquipmentTypeRestrictionContext";

function EquipmentTypeRestrictionEditDialog() {
    const {
        equipmentTypeRestriction,
        selectedEquipmentType,
        handleChange,
        showDialog,
        setShowDialog,
        saveEquipmentTypeRestriction,
        hasError,
    } = useEquipmentTypeRestrictionContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                saveEquipmentTypeRestriction();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title =
        equipmentTypeRestriction.id == null
            ? `Új elem felvitele ${selectedEquipmentType?.name} eszköztípushoz`
            : `Elem szerkesztése ${selectedEquipmentType?.name} eszköztípushoz`;

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Név"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={equipmentTypeRestriction.name}
                    name="name"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Érték"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={equipmentTypeRestriction.value}
                    name="value"
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EquipmentTypeRestrictionEditDialog;
