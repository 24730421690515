import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { EQUIPMENT_TYPE_ATTRIBUTE_URL } from "./Url";

export function useEquipmentTypeAttributeApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getEquipmentTypeAttributesByType(
        equipmentTypeId,
        onSuccess
    ) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(
                    `${EQUIPMENT_TYPE_ATTRIBUTE_URL}/list/${equipmentTypeId}`,
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return { getEquipmentTypeAttributesByType };
}
