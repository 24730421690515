import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReportApi } from "../../../api/reportApi";
import { getCurrentYear } from "../../../utils/DateUtils";

const ReportListContext = createContext({});

export const ReportListProvider = ({ isReturn, children }) => {
    const { getReportsByReportTypeAndYear } = useReportApi();
    const location = useLocation();
    const [reports, setReports] = useState([]);
    const [reportType, setReportType] = useState(
        location.pathname.split("/")[2]
    );
    const [selectedYear, setSelectedYear] = useState(getCurrentYear());

    useEffect(() => {
        setReportType(reportType);
    }, []);

    useEffect(() => {
        fetchReports(reportType, selectedYear);
    }, [selectedYear]);

    function fetchReports(reportType, year) {
        getReportsByReportTypeAndYear(reportType, year, (response) => {
            setReports(response.data.reports);
        });
    }

    return (
        <ReportListContext.Provider
            value={{
                selectedYear,
                setSelectedYear,
                reports,
                reportType,
            }}
        >
            {children}
        </ReportListContext.Provider>
    );
};

export default ReportListContext;
