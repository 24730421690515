import { Backdrop, CircularProgress } from "@mui/material";
import useFullScreenProgressBarContext from "./useFullScreenProgressBarContext";

function FullScreenProgressBar() {
    const { showFullScreenProgressBar } = useFullScreenProgressBarContext();

    return (
        <Backdrop
            sx={{ backdropFilter: "blur(2px)", zIndex: 12000 }}
            open={showFullScreenProgressBar}
        >
            <CircularProgress thickness={5} size="5rem" color="primary" />
        </Backdrop>
    );
}

export default FullScreenProgressBar;
