var emailInit = localStorage.getItem("lastEmail") || "";

export const LOGIN_INITIAL_STATE = {
    email: emailInit,
    password: "",
};

export const LOGIN_ACTION_TYPES = {
    SET_INPUT_VALUE: "SET_INPUT_VALUE",
    CLEAR_INPUT_VALUES: "CLEAR_INPUT_VALUES",
};

export const loginReducer = (state, action) => {
    switch (action.type) {
        case LOGIN_ACTION_TYPES.SET_INPUT_VALUE:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case LOGIN_ACTION_TYPES.CLEAR_INPUT_VALUES:
            return {
                ...state,
                email: "",
                password: "",
            };
        default:
            break;
    }
};
