import { EquipmentCategoryProvider } from "./EquipmentCategoryContext";
import EquipmentCategoryPageContent from "./EquipmentCategoryPageContent";

function EquipmentCategoryPage() {
    return (
        <EquipmentCategoryProvider>
            <EquipmentCategoryPageContent />
        </EquipmentCategoryProvider>
    );
}

export default EquipmentCategoryPage;
