import { createContext, useState, useLayoutEffect, useEffect } from "react";
import dayjs from "dayjs";
import { useEquipmentApi } from "../../../api/equipmentApi";
import { useCertificateIssuerApi } from "../../../api/certificateIssuerApi";
import { useEquipmentTypeApi } from "../../../api/equipmentTypeApi";
import { useManufacturerApi } from "../../../api/manufacturerApi";
import { useEquipmentCategoryApi } from "../../../api/equipmentCategoryApi";

const emptyEquipment = {
    id: null,
    name: null,
    typeNumber: null,
    certificateNumber: null,
    certificateDate: null,
    certificateIssuer: null,
    category: null,
    type: null,
    manufacturer: null,
    active: true,
    csv: null,
    manufacturerDeclarationNumber: null,
};

const emptyErrors = {
    name: null,
    csv: null,
    certificateNumber: null,
    category: null,
    manufacturerDeclarationNumber: null,
};

const EquipmentContext = createContext({});

export const EquipmentProvider = ({ children }) => {
    const { getEquipments, uploadEquipmentCsv, deleteEquipment } =
        useEquipmentApi();
    const { getCertificateIssuers } = useCertificateIssuerApi();
    const { getEquipmentTypes } = useEquipmentTypeApi();
    const { getEquipmentCategories } = useEquipmentCategoryApi();
    const { getManufacturers } = useManufacturerApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showNewEquipmentDialog, setShowNewEquipmentDialog] = useState(false);

    const [equipments, setEquipments] = useState([]);

    const [equipmentCategories, setEquipmentCategories] = useState([]);

    const [allEquipmentTypes, setAllEquipmentTypes] = useState([]);

    const [equipmentTypes, setEquipmentTypes] = useState([]);

    const [manufacturers, setManufacturers] = useState([]);

    const [certificateIssuers, setCertificateIssuers] = useState([]);

    const [errors, setErrors] = useState(emptyErrors);

    const [data, setData] = useState({ emptyEquipment });

    const hasError =
        !data.name ||
        !data.category ||
        errors.name ||
        errors.category ||
        (data.category?.id === 1 && data.certificateNumber === null) ||
        data.certificateNumber?.trim() === "" ||
        (data.category?.id === 2 &&
            data.manufacturerDeclarationNumber === null) ||
        errors.manufacturerDeclarationNumber;

    const showTableEditButtons = data.id == null ? false : true;

    function validate(name, value) {
        switch (name) {
            case "name":
            case "category":
                const equipmentTypes = allEquipmentTypes.filter(
                    (type) => value.id === type.category.id
                );
                setEquipmentTypes(equipmentTypes);
                break;
            case "manufacturerDeclarationNumber":
            case "certificateNumber": {
                if (!value || value.trim() === "") {
                    setErrors({
                        ...errors,
                        [name]: "Kötelező mező",
                    });
                } else {
                    setErrors({
                        ...errors,
                        [name]: null,
                    });
                }
                break;
            }
            case "manufacturer":
                if (!value) {
                }
                break;
            default:
                break;
        }
    }

    const handleCertificateIssueDate = (date) => {
        setData((prevData) => ({
            ...prevData,
            certificateDate: date,
        }));
    };

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        validate(name, value);

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchEquipments();
        fetchEquipmentTypes();
        fetchEquipmentCategories();
        fetchManufacturers();
        fetchCertificateIssuers();
    }, []);

    function selectEquipment(id) {
        if (id != null) {
            const equipment = equipments.find(
                (equipment) => equipment.id === id
            );
            equipment.certificateDate = equipment.certificateDate
                ? dayjs(equipment.certificateDate)
                : null;
            setData(equipment);
        } else {
            setData(emptyEquipment);
        }
    }

    useLayoutEffect(() => {
        if (!showNewEquipmentDialog) {
            setErrors(emptyErrors);
        }
    }, [showNewEquipmentDialog]);

    function fetchEquipments() {
        getEquipments((response) => {
            setEquipments(response.data.equipments);
            setData(emptyEquipment);
        });
    }

    function fetchEquipmentCategories() {
        getEquipmentCategories((response) => {
            setEquipmentCategories(response.data.equipmentCategories);
        });
    }

    function fetchEquipmentTypes() {
        getEquipmentTypes((response) => {
            setAllEquipmentTypes(response.data.equipmentTypes);
        });
    }

    function fetchManufacturers() {
        getManufacturers((response) => {
            setManufacturers(response.data.manufacturers);
        });
    }

    function fetchCertificateIssuers() {
        getCertificateIssuers((response) => {
            setCertificateIssuers(response.data.certificateIssuers);
        });
    }

    function uploadCsv() {
        uploadEquipmentCsv(data.csv, (response) => {
            fetchEquipments();
        });
    }

    function deleteEquip() {
        deleteEquipment(data.id, (response) => {
            fetchEquipments();
        });
    }

    return (
        <EquipmentContext.Provider
            value={{
                data,
                setData,
                fetchEquipments,
                equipmentTypes,
                equipmentCategories,
                manufacturers,
                certificateIssuers,
                showNewEquipmentDialog,
                setShowNewEquipmentDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                equipments,
                setEquipments,
                selectEquipment,
                errors,
                hasError,
                handleChange,
                handleCertificateIssueDate,
                deleteEquip,
                uploadCsv,
                showTableEditButtons,
            }}
        >
            {children}
        </EquipmentContext.Provider>
    );
};

export default EquipmentContext;
