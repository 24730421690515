import { OperatorProvider } from "../../basicdata/operator/OperatorContext";
import { ReportLocationProvider } from "./ReportLocationContext";
import ReportLocationPageContent from "./ReportLocationPageContent";

function ReportLocationPage() {
    return (
        <OperatorProvider>
            <ReportLocationProvider>
                <ReportLocationPageContent />
            </ReportLocationProvider>
        </OperatorProvider>
    );
}

export default ReportLocationPage;
