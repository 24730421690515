export const APPBAR_INITIAL_STATE = {
    anchorElNav: null,
    anchorElUser: null
}

export const APPBAR_ACTION_TYPES = {
    OPEN_NAV_MENU: "OPEN_NAV_MENU",
    CLOSE_NAV_MENU: "CLOSE_NAV_MENU",
    OPEN_USER_MENU: "OPEN_USER_MENU",
    CLOSE_USER_MENU: "CLOSE_USER_MENU"
}

export const appbarReducer = (state, action) => {
    switch (action.type) {
        case APPBAR_ACTION_TYPES.OPEN_NAV_MENU: 
            return {
                ...state,
                anchorElNav: action.payload
            }
        case APPBAR_ACTION_TYPES.CLOSE_NAV_MENU: 
            return {
                ...state,
                anchorElNav: null
            }
        case APPBAR_ACTION_TYPES.OPEN_USER_MENU: 
            return {
                ...state,
                anchorElUser: action.payload
            }
        case APPBAR_ACTION_TYPES.CLOSE_USER_MENU: 
            return {
                ...state,
                anchorElUser: null
            }            
        default:
            break;
    }
}