import { createContext, useState, useLayoutEffect, useEffect } from "react";
import fetchCities from "../../../api/citiesApi";
import { useLocationApi } from "../../../api/locationApi";
import { useOperatorApi } from "../../../api/operatorApi";

const emptyLocation = {
    id: null,
    name: null,
    city: null,
    zip: null,
    address: null,
    operator: null,
    contactPerson: null,
    csv: null,
};

const emptyErrors = {
    name: null,
    city: null,
    zip: null,
    address: null,
    operator: null,
    contactPerson: null,
    csv: null,
};

const LocationContext = createContext({});

export const LocationProvider = ({ children }) => {
    const { getLocations, saveLocation, uploadLocationCsv, deleteLocation } =
        useLocationApi();
    const { getOperators } = useOperatorApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [locations, setLocations] = useState([]);
    const [operators, setOperators] = useState([]);
    const [errors, setErrors] = useState(emptyErrors);
    const [allCities, setAllCities] = useState([]);
    const [cities, setCities] = useState([]);
    const [data, setData] = useState({ emptyOperator: emptyLocation });

    const hasError =
        !data.name ||
        !data.city ||
        !data.zip ||
        !data.address ||
        !data.operator ||
        errors.name ||
        errors.city ||
        errors.zip ||
        errors.address;

    const showTableEditButtons = data.id != null;

    function validate(name, value) {
        switch (name) {
            case "name": {
                break;
            }
            case "city": {
                break;
            }
            case "zip": {
                if (value.toString().length > 0) {
                    const city = allCities.filter((city) =>
                        city.zip.startsWith(value)
                    );
                    if (city.length === 1) {
                        setData((prevData) => ({
                            ...prevData,
                            city: city[0].city,
                        }));
                    }
                    setCities(city);
                }
                break;
            }
            case "address": {
                break;
            }
            case "operator": {
                break;
            }
            case "contactPerson": {
                break;
            }
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        validate(name, value);

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchLocations();
        fetchOperators();
        fetchCities().then((result) => {
            setAllCities(result);
        });
    }, []);

    function setSelectedLocation(id) {
        if (id != null) {
            const location = locations.find((location) => location.id === id);
            setData(location);
        } else {
            setCities([]);
            setData(emptyLocation);
        }
    }

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    function fetchLocations() {
        getLocations((response) => {
            setData(emptyLocation);
            setLocations(response.data.locations);
        });
    }

    function fetchOperators() {
        getOperators((response) => {
            setOperators(response.data.operators);
        });
    }

    function save() {
        saveLocation(data, (response) => {
            fetchLocations();
        });
    }

    function uploadCsv() {
        uploadLocationCsv(data.csv, (repsonse) => {
            fetchLocations();
        });
    }

    function deleteLoc() {
        deleteLocation(data.id, (response) => {
            fetchLocations();
        });
    }

    return (
        <LocationContext.Provider
            value={{
                cities,
                data,
                operators,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                locations,
                setLocations,
                setSelectedLocation,
                errors,
                hasError,
                handleChange,
                save,
                deleteLoc,
                uploadCsv,
                showTableEditButtons,
            }}
        >
            {children}
        </LocationContext.Provider>
    );
};

export default LocationContext;
