import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useInspectionTypeContext from "./useInspectionTypeContext";
import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    Typography,
} from "@mui/material";

function InspectionEditDialog() {
    const {
        data,
        equipmentCategories,
        handleChange,
        showDialog,
        setShowDialog,
        save,
        hasError,
    } = useInspectionTypeContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title =
        data.id == null
            ? "Új ellenőrzés típus"
            : "Ellenőrzés típus szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <FormControlLabel
                    sx={{ mb: 2 }}
                    control={
                        <Typography sx={{ ml: 2 }}>{data.code}</Typography>
                    }
                    label="Kód:"
                    labelPlacement="start"
                ></FormControlLabel>

                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Név"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.name}
                    name="name"
                    required
                />
                <Autocomplete
                    sx={{ width: "100%", my: 1 }}
                    id="free-solo-demo"
                    name="categoryName"
                    value={data.category}
                    onChange={(event, newValue) => {
                        if (event != null) {
                            event.target.type = "autocomplete";
                            event.target.name = "category";
                            event.target.selectedOption = newValue;
                            handleChange(event);
                        }
                    }}
                    options={equipmentCategories}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Eszköz kategória" />
                    )}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            name="default"
                            onChange={handleChange}
                            checked={data.default}
                        />
                    }
                    label="Alapértelmezett"
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default InspectionEditDialog;
