import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { useEquipmentCategoryApi } from "../../../api/equipmentCategoryApi";
import { useEquipmentTypeApi } from "../../../api/equipmentTypeApi";

const emptyEquipmentType = {
    id: null,
    name: null,
    description: null,
    category: null,
    csv: null,
};

const emptyErrors = {
    name: null,
    csv: null,
};

const EquipmentTypeContext = createContext({});

export const EquipmentTypeProvider = ({ children }) => {
    const {
        getEquipmentTypes,
        saveEquipmentType,
        deleteEquipmentType,
        uploadEquipmentTypeCsv,
    } = useEquipmentTypeApi();
    const { getEquipmentCategories } = useEquipmentCategoryApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [equipmentTypes, setEquipmentTypes] = useState([]);

    const [errors, setErrors] = useState(emptyErrors);

    const [equipmentCategories, setEquipmentCategories] = useState([]);

    const [data, setData] = useState({
        emptyEquipmentType,
    });

    const hasError = !data.name || errors.name;

    const showTableEditButtons = data.id === null ? false : true;

    function processData(name, value) {
        switch (name) {
            case "name": {
                break;
            }
            case "description": {
                break;
            }
            case "category": {
                break;
            }
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        processData(e.target.name, value);

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchEquipmentTypes();
        fetchEquipmentCategories();
    }, []);

    function setSelectedEquipmentType(id) {
        if (id != null) {
            const equipmentType = equipmentTypes.find(
                (equipmentType) => equipmentType.id === id
            );
            setData(equipmentType);
        } else {
            setData(emptyEquipmentType);
        }
    }

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    function fetchEquipmentCategories() {
        getEquipmentCategories((response) => {
            setEquipmentCategories(response.data.equipmentCategories);
        });
    }

    function fetchEquipmentTypes() {
        getEquipmentTypes((response) => {
            setEquipmentTypes(response.data.equipmentTypes);
        });
    }

    function save() {
        saveEquipmentType(data, (response) => {
            setData(emptyEquipmentType);
            fetchEquipmentTypes();
        });
    }

    function uploadCsv() {
        uploadEquipmentTypeCsv(data.csv, (response) => {
            fetchEquipmentTypes();
        });
    }

    function deleteEquipType() {
        deleteEquipmentType(data.id, (response) => {
            fetchEquipmentTypes();
        });
    }

    return (
        <EquipmentTypeContext.Provider
            value={{
                equipmentCategories,
                data,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                equipmentTypes,
                setEquipmentTypes,
                errors,
                hasError,
                handleChange,
                save,
                deleteEquipType,
                uploadCsv,
                showTableEditButtons,
                setSelectedEquipmentType,
            }}
        >
            {children}
        </EquipmentTypeContext.Provider>
    );
};

export default EquipmentTypeContext;
