import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import PrevNextButtons from "../PrevNextButtons";
import useReportContext from "../useReportContext";
import { ReportsByYearOverviewTable } from "./ReportsByYearOverviewTable";
import useReportBaseDataContext from "./useReportBaseDataContext";
import {
    getCurrentYear,
    getYearsFromNowTo2023,
} from "../../../utils/DateUtils";

const ReportBaseDataPageContent = ({ isReturn }) => {
    const {
        data,
        handleDate,
        handleChange,
        saveReport,
        selectedYearForList,
        setSelectedYearForList,
        selectedYearForReport,
        setSelectedYearForReport,
    } = useReportBaseDataContext();

    const { report, reportConfig, updateReport } = useReportContext();

    const number = !isReturn ? data.number : null;
    const currentYear = getCurrentYear();
    const year = isReturn ? currentYear : data?.year ?? currentYear;
    if (!reportConfig) return;
    return (
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justify="center"
                        xs={6}
                    >
                        <Typography>
                            {reportConfig.reportNamePrefix}&ensp;
                        </Typography>
                        <TextField
                            id="outlined-basic"
                            label="Sorszám"
                            name="number"
                            variant="outlined"
                            disabled={!isReturn && data.id != null}
                            value={number}
                            onChange={handleChange}
                            type="number"
                            required
                        />
                        <Typography>&ensp;/&ensp;</Typography>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">
                                Év
                            </InputLabel>
                            <Select
                                id="year-selection"
                                value={selectedYearForReport}
                                label="Év"
                                disabled={report.baseData.id != null}
                                onChange={(event) =>
                                    setSelectedYearForReport(event.target.value)
                                }
                            >
                                {getYearsFromNowTo2023().map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <TextField
                        sx={{ my: 4, width: "100%" }}
                        id="outlined-basic"
                        label="Rövid megnevezés"
                        name="shortName"
                        value={data.shortName}
                        variant="outlined"
                        onChange={handleChange}
                        required
                    />
                    <DesktopDatePicker
                        sx={{ my: 4 }}
                        name="date"
                        label="Ellenőrzés dátuma *"
                        inputFormat="YYYY.MM.DD"
                        value={data.date}
                        onChange={handleDate}
                        maxDate={Date()}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <TextField
                        sx={{ my: 4, width: "100%" }}
                        id="outlined-basic"
                        label="Trello card id"
                        name="trelloCardId"
                        value={data.trelloCardId}
                        variant="outlined"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl sx={{ mb: 4 }}>
                        <InputLabel id="demo-simple-select-label">
                            Év
                        </InputLabel>
                        <Select
                            id="year-selection"
                            value={selectedYearForList}
                            label="Év"
                            onChange={(event) =>
                                setSelectedYearForList(event.target.value)
                            }
                        >
                            {getYearsFromNowTo2023().map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box height={400}>
                        <ReportsByYearOverviewTable
                            reportType={report.baseData.reportType.urlparam}
                            year={selectedYearForList}
                        />
                    </Box>
                </Grid>
            </Grid>
            <PrevNextButtons
                nextFunction={() =>
                    saveReport()
                        .then((report) => {
                            updateReport("baseData", report);
                        })
                        .then(() => true)
                        .catch((e) => false)
                }
            />
        </Box>
    );
};

export default ReportBaseDataPageContent;
