import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useEquipmentTypeRestrictionContext from "./useEquipmentTypeRestrictionContext";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.5 },
    { field: "value", headerName: "Érték", minWidth: 70, flex: 0.5 },
];

function EquipmentTypeRestrictionTable() {
    const { equipmentTypeRestrictions, setEditedId, showTableLoading } =
        useEquipmentTypeRestrictionContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        rowSelected(id);
    }

    function rowSelected(id) {
        setEditedId(id);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={equipmentTypeRestrictions}
                loading={showTableLoading}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default EquipmentTypeRestrictionTable;
