import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
    DataGrid,
    GridToolbarContainer,
    GridRowModes,
    GridActionsCellItem,
    useGridApiContext,
} from "@mui/x-data-grid";
import { Stack } from "@mui/system";
import {
    Dialog,
    FormControl,
    MenuItem,
    Popover,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import usePlaygroundReportEquipmentContext from "../usePlaygroundReportEquipmentContext";
import { EditType } from "../PlaygroundReportEquipmentContext";
import { randomId } from "@mui/x-data-grid-generator";
import { useCallback, useEffect, useState } from "react";
import usePlaygroundReportEquipmentIssuesContext from "./usePlaygroundReportEquipmentIssuesContext";
import PrevNextButtons from "../../PrevNextButtons";
import useReportImageContext from "../../useReportImagesContext";
import ImageGalleryWithDelete from "../../../common/CustomImageList";

const rowHeight = 100;

class SelectedRowAndImage {
    constructor(equipmentId, selectedImage) {
        this.equipmentId = equipmentId;
        this.selectedImage = selectedImage;
    }
}

function EditToolbar(props) {
    const { currentRow, setRows, setRowModesModel } = props;

    const handleClick = () => {
        const id = randomId();
        setRows((oldRows) => [
            ...oldRows,
            { id, standard: {}, standardIssue: {}, image: null, isNew: true },
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "standard" },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClick}
                disabled={currentRow != null}
            >
                Új hiba
            </Button>
        </GridToolbarContainer>
    );
}

EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setRows: PropTypes.func.isRequired,
};

function PlaygroundReportEquipmentIssuesTable() {
    const { images } = useReportImageContext();
    const {
        standards,
        standardIssues,
        reportEquipmentIssues,
        saveReportEquipmentIssue,
        deleteIssue,
    } = usePlaygroundReportEquipmentIssuesContext();
    const { setEditType, editedEquipment } =
        usePlaygroundReportEquipmentContext();

    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [currentRow, setCurrentRow] = useState(null);
    const [hasRowEditError, setHasRowEditError] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [openGallery, setOpenGallery] = useState(false);

    useEffect(() => {
        setRows(reportEquipmentIssues);
    }, [reportEquipmentIssues]);

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (row) => () => {
        setCurrentRow(row);
        setRowModesModel({
            ...rowModesModel,
            [row.id]: { mode: GridRowModes.Edit },
        });
    };

    const handleSaveClick = (row) => () => {
        setRowModesModel({
            ...rowModesModel,
            [row.id]: { mode: GridRowModes.View },
        });
        setCurrentRow(null);
    };

    const handleDeleteClick = (row) => () => {
        setRows(rows.filter((r) => r.id !== row.id));
        deleteIssue(row.id);
        setCurrentRow(null);
    };

    const handleCancelClick = (row) => () => {
        setRowModesModel({
            ...rowModesModel,
            [row.id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((r) => r.id === row.id);
        if (editedRow.isNew) {
            setRows(rows.filter((r) => r.id !== row.id));
        }
        setCurrentRow(null);
    };

    const navigateToEquipmentList = (id) => {
        setEditType(EditType.None);
    };

    const processRowUpdate = useCallback(
        async (newRow) => {
            let standard = newRow.standard;
            if (typeof newRow.standard !== "object") {
                standard = standards.find(
                    (standard) => standard.code === newRow.standard
                );
            }
            let standardIssue = newRow.standardIssue;
            if (typeof newRow.standardIssue !== "object") {
                standardIssue = standardIssues.find(
                    (standardIssue) =>
                        standardIssue.standardSection === newRow.standardSection
                );
            }
            const updatedRow = {
                ...newRow,
                standard: standard,
                standardIssue: standardIssue,
                image: newRow.image,
                isNew: false,
            };
            saveReportEquipmentIssue(updatedRow);
            setRows(
                rows.map((row) => (row.id === newRow.id ? updatedRow : row))
            );
            return updatedRow;
        },
        [rows, standards, images]
    );

    function IssueImageCell(props) {
        const [anchorEl, setAnchorEl] = useState(null);

        const handlePopoverOpen = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);

        const thumbnail = images.find(
            (image) => image.name === props.row.image
        )?.thumbnail;

        return thumbnail ? (
            <div>
                <Box
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    <a href="#">
                        <img
                            src={`data:image/jpeg;base64, ${thumbnail}`}
                            height={rowHeight}
                        />
                    </a>
                </Box>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <img
                        height={200}
                        src={`data:image/jpeg;base64, ${thumbnail}`}
                    />
                </Popover>
            </div>
        ) : (
            <Box></Box>
        );
    }
    const renderImageCell = (params) => {
        return <IssueImageCell {...params} />;
    };

    function ImageEditCell(props) {
        const { id, row, field } = props;
        const [anchorEl, setAnchorEl] = useState(null);
        const apiRef = useGridApiContext();

        const handlePopoverOpen = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
            setAnchorEl(null);
        };

        const thumbnail = images.find(
            (image) => image.name === props.row.image
        )?.thumbnail;

        function openImageGallery(id, imageName) {
            setOpenGallery(true);
            setSelectedImage(new SelectedRowAndImage(id, imageName));
        }

        const saveSelectedImage = async (image) => {
            setOpenGallery(false);
            await apiRef.current.setEditCellValue({
                id,
                field,
                value: image.name,
            });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <div>
                {thumbnail ? (
                    <Box
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <a
                            href="#"
                            onClick={(event) => {
                                openImageGallery(row.id, row.image);
                            }}
                        >
                            <img
                                src={`data:image/jpeg;base64, ${thumbnail}`}
                                height={rowHeight}
                            />
                        </a>
                    </Box>
                ) : (
                    <Button
                        variant="text"
                        onClick={() => openImageGallery(row.id, row.image)}
                    >
                        Nincs
                    </Button>
                )}
                <Dialog
                    sx={{ maxHeight: "600px" }}
                    fullWidth
                    maxWidth="md"
                    open={openGallery}
                    onClose={() => setOpenGallery(false)}
                >
                    <ImageGalleryWithDelete
                        images={[...images]}
                        selectedImage={selectedImage?.selectedImage}
                        showDelete={false}
                        onDelete={() => {}}
                        onImageSelect={(image, index) => {
                            saveSelectedImage(image);
                        }}
                    />
                </Dialog>
            </div>
        );
    }

    const renderImageEditCell = (params) => {
        return <ImageEditCell {...params} />;
    };

    function SelectStandardCell(props) {
        const { id, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (props, event) => {
            await apiRef.current.setEditCellValue({
                id,
                field,
                value: event.target.value,
            });
            apiRef.current.stopCellEditMode({ id, field });
        };

        const selectedStandard = standards.find(
            (standard) => standard.code === props.row.standard?.code
        );

        return (
            <FormControl fullWidth>
                <Select
                    style={{ height: rowHeight }}
                    value={selectedStandard}
                    onChange={(e) => handleChange(props, e)}
                    renderValue={(item) => item.code}
                >
                    {standards.map((standard) => (
                        <MenuItem key={standard.id} value={standard}>
                            {standard.code}
                            <br />
                            {standard.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    const renderStandardCell = (params) => {
        return <SelectStandardCell {...params} />;
    };

    function SelectStandardIssueCell(props) {
        const { id, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (props, event) => {
            await apiRef.current.setEditCellValue({
                id,
                field,
                value: event.target.value,
            });
            apiRef.current.stopCellEditMode({ id, field });
        };

        const selectedStandardIssue = standardIssues.find(
            (standardIssue) => standardIssue.id === props.row.standardIssue?.id
        ) ?? {
            standardSection: props.row.standardSection,
            description: props.row.description,
            dangerous: props.row.dangerous,
        };

        const filteredIssues = standardIssues.filter(
            (item) => item.standard === props.row.standard.id
        );
        return (
            <FormControl fullWidth>
                <Select
                    style={{ height: rowHeight }}
                    value={selectedStandardIssue}
                    onChange={(e) => handleChange(props, e)}
                    renderValue={(item) => item.description}
                >
                    {filteredIssues.map((standardIssue) => (
                        <MenuItem key={standardIssue.id} value={standardIssue}>
                            <Typography
                                variant="inherit"
                                color={
                                    standardIssue.dangerous
                                        ? "Red"
                                        : "text.primary"
                                }
                            >
                                {standardIssue.description}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    const renderStandardIssueCell = (params) => {
        return <SelectStandardIssueCell {...params} />;
    };

    const columns = [
        {
            field: "standard",
            headerName: "Vonatkozó szabvány",
            minWidth: 70,
            flex: 0.3,
            editable: true,
            renderEditCell: renderStandardCell,
            renderCell: (params) => params.row.standard.code,
            preProcessEditCellProps: (params) => {
                const hasError = !params.props.value;
                setHasRowEditError(
                    hasError ||
                        params.otherFieldsProps.standardIssue.value === "" ||
                        params.otherFieldsProps.image.value === null
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "standardIssue",
            headerName: "Hiba leírás",
            minWidth: 70,
            flex: 0.4,
            editable: true,
            renderEditCell: renderStandardIssueCell,
            renderCell: (params) => (
                <TextField
                    variant="standard"
                    style={{ width: params.colDef.computedWidth }}
                    value={
                        params.row.standardIssue
                            ? params.row.standardIssue.description
                            : params.row.description
                    }
                    multiline
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
            ),
            preProcessEditCellProps: (params) => {
                const hasError = params.props.value === "";
                setHasRowEditError(
                    hasError ||
                        params.otherFieldsProps.image.value === null ||
                        !params.otherFieldsProps.standard.value
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "image",
            headerName: "Kép",
            minWidth: 70,
            flex: 0.2,
            editable: true,
            renderEditCell: renderImageEditCell,
            renderCell: renderImageCell,
            preProcessEditCellProps: (params) => {
                const hasError = params.props.value === null;
                setHasRowEditError(
                    hasError ||
                        params.otherFieldsProps.standardIssue.value === "" ||
                        !params.otherFieldsProps.standard.value
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (params) => {
                const id = params.id;
                const isInEditMode =
                    rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            disabled={hasRowEditError}
                            onClick={handleSaveClick(params.row)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(params.row)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(params.row)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(params.row)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <div style={{ height: 500, width: "100%" }}>
            <Stack
                direction="row"
                spacing={2}
                m={2}
                justifyContent="space-between"
            >
                <Typography variant="h5">
                    {editedEquipment?.equipment?.name} hibái
                </Typography>
            </Stack>
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                rowHeight={rowHeight}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        currentRow,
                        setRows,
                        setRowModesModel,
                    },
                }}
                experimentalFeatures={{ newEditingApi: true }}
            />
            <PrevNextButtons
                prevFunction={() => navigateToEquipmentList()}
                nextLabel="Tovább"
                nextFunction={() => navigateToEquipmentList()}
            />
        </div>
    );
}

export default PlaygroundReportEquipmentIssuesTable;
