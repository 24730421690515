import { createContext, useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useGeneralErrorContext from "../../../common/generalerrorsnackbar/useGeneralErrorContext";
import { REPORT_URL } from "../../../../api/Url";
import usePlaygroundReportEquipmentContext from "../usePlaygroundReportEquipmentContext";
import useReportContext from "../../useReportContext";
import { useReportApi } from "../../../../api/reportApi";
import { useEquipmentTypeAttributeApi } from "../../../../api/equipmentTypeAttributeApi";

const PlaygroundReportEquipmentAttributesContext = createContext({});

export const PlaygroundReportEquipmentAttributesProvider = ({ children }) => {
    const { getEquipmentTypeAttributesByType } = useEquipmentTypeAttributeApi();
    const { getReportEquipmentAttributes } = useReportApi();
    const { setNextEnabled } = useReportContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();
    const [showTableLoading, setShowTableLoading] = useState(false);
    const { editedEquipment } = usePlaygroundReportEquipmentContext();

    const [equipmentTypes] = useState([]);

    const [equipmentTypeAttributes, setEquipmentTypeAttributes] = useState([]);
    const [reportEquipmentAttributes, setReportEquipmentAttributes] = useState(
        []
    );

    setNextEnabled(true);

    useEffect(() => {
        fetchEquipmentTypeAttributesByType();
        fetchReportEquipmentAttributes();
    }, [editedEquipment]);

    function fetchEquipmentTypeAttributesByType() {
        getEquipmentTypeAttributesByType(
            editedEquipment.equipment.type.id,
            (response) => {
                setEquipmentTypeAttributes(
                    response.data.equipmentTypeAttributes
                );
            }
        );
    }

    function fetchReportEquipmentAttributes() {
        getReportEquipmentAttributes(editedEquipment.id, (response) => {
            setReportEquipmentAttributes(
                response.data.reportEquipmentAttributes
            );
        });
    }

    const saveReportEquipmentAttributes = async (params) => {
        let success = false;
        setShowTableLoading(true);
        try {
            const attributes = [];
            params.forEach((value, key) => {
                attributes.push({
                    id: value.id,
                    attributeId: key,
                    value: value.value,
                });
            });
            const request = { attributes: attributes };
            const response = await axiosPrivate.put(
                `${REPORT_URL}/reportequipment/${editedEquipment.id}/attributes`,
                JSON.stringify(request),
                {
                    withCredentials: true,
                }
            );
            console.log(
                JSON.stringify(response.data.reportEquipmentAttributes)
            );
            setReportEquipmentAttributes(
                response.data.reportEquipmentAttributes
            );
            success = true;
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowTableLoading(false);
        }
        return success;
    };

    return (
        <PlaygroundReportEquipmentAttributesContext.Provider
            value={{
                equipmentTypes,
                equipmentTypeAttributes,
                reportEquipmentAttributes,
                saveReportEquipmentAttributes,
                showTableLoading,
            }}
        >
            {children}
        </PlaygroundReportEquipmentAttributesContext.Provider>
    );
};

export default PlaygroundReportEquipmentAttributesContext;
