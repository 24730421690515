import useAdminTemplateContext from "../admin/templates/useAdminTemplateContext";

function FileUpload({ name, fileTypes, data, handleChange }) {
    return (
        <div>
            <input
                type="file"
                name={name}
                accept={fileTypes}
                onChange={handleChange}
            />
            {data.template != null ? (
                <div>
                    <p>Filename: {data.template.name}</p>
                    <p>Filetype: {data.template.type}</p>
                    <p>Size in bytes: {data.template.size}</p>
                    <p>
                        lastModifiedDate:{" "}
                        {data.template.lastModifiedDate?.toLocaleDateString()}
                    </p>
                </div>
            ) : (
                <p>Select a file to show details</p>
            )}
        </div>
    );
}

export default FileUpload;
