import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import AdminUserEditDialog from "./AdminUserEditDialog";
import AdminUserTable from "./AdminUserTable";
import useAdminUserContext from "./useAdminUserContext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";
import AlertDialog from "../../common/AlertDialog";
import CustomSnackbar from "../../common/CustomSnackBar";
import PageTitleWithBackButton from "../../common/PageTitleWithBackButton";

function AdminUserPageContent() {
    const {
        setShowDialog,
        showTableEditButtons,
        deleteUsr,
        selectUser,
        requestPass,
        showDeleteConfirm,
        setShowDeleteConfirm,
        snackbar,
        setSnackbar,
    } = useAdminUserContext();

    const handleClickOpen = (e) => {
        switch (e.target.name) {
            case "new": {
                selectUser(null);
                setShowDialog(true);
                break;
            }
            case "edit": {
                setShowDialog(true);
                break;
            }
            case "delete": {
                setShowDeleteConfirm(true);
                break;
            }
            case "passwordreset": {
                requestPass();
                break;
            }
            default:
                break;
        }
    };

    const handleDeleteConfirm = (positive) => {
        if (positive) {
            deleteUsr();
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <PageTitleWithBackButton title="Felhasználók" />
            <Stack direction="row" spacing={2} m={2}>
                <Button
                    sx={{ position: "right" }}
                    variant="contained"
                    name="new"
                    onClick={handleClickOpen}
                >
                    Új felhasználó
                </Button>
                {showTableEditButtons && (
                    <Box>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="edit"
                            startIcon={<EditIcon />}
                            onClick={handleClickOpen}
                        >
                            Szerkesztés
                        </Button>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="delete"
                            startIcon={<DeleteIcon />}
                            onClick={handleClickOpen}
                        >
                            Törlés
                        </Button>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="passwordreset"
                            startIcon={<KeyIcon />}
                            onClick={handleClickOpen}
                        >
                            Új jelszó
                        </Button>
                    </Box>
                )}
            </Stack>
            <AdminUserTable />
            <AdminUserEditDialog />
            <AlertDialog
                open={[showDeleteConfirm, setShowDeleteConfirm]}
                message="Törlöd a felhasználót?"
                positiveButton="Törlés"
                negativeButton="Mégse"
                buttonHandler={handleDeleteConfirm}
            />
            <CustomSnackbar
                message="Sikeres jelszó visszaállítás igénylés"
                visible={[snackbar, setSnackbar]}
            />
        </Box>
    );
}

export default AdminUserPageContent;
