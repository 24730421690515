import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useStandardContext from "./useStandardContext";
import { Autocomplete, Stack } from "@mui/material";
import FileUpload from "../../common/FileUpload";

function StandardEditDialog() {
    const {
        data,
        equipmentTypes,
        handleChange,
        showDialog,
        setShowDialog,
        save,
        errors,
        hasError,
    } = useStandardContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title = data.id === null ? "Új szabvány" : "Szabvány szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Szabvány"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.code}
                    error={errors.code != null}
                    helperText={errors.code}
                    name="code"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Megnevezés"
                    multiline
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.name}
                    error={errors.name != null}
                    helperText={errors.name}
                    name="name"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Fejezet"
                    multiline
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.section}
                    error={errors.section != null}
                    helperText={errors.section}
                    name="section"
                />
                <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                    <Autocomplete
                        sx={{ width: "100%" }}
                        id="free-solo-demo"
                        multiple
                        name="equipmentType"
                        value={data.types}
                        disabled={equipmentTypes.length === 0}
                        onChange={(event, newInputValue) => {
                            if (event != null) {
                                event.target.type = "autocomplete";
                                event.target.name = "types";
                                event.target.selectedOption = newInputValue;
                                handleChange(event);
                            }
                        }}
                        options={equipmentTypes}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Eszköz típus" />
                        )}
                    />
                </Stack>
                <FileUpload
                    data={data}
                    fileTypes=".pdf"
                    handleChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default StandardEditDialog;
