import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

/* 
    severity:
    error
    warning
    info
    success 
*/

export default function CustomSnackbar(props) {
    const [visible, setVisible] = props.visible;
    const severity = props.severity ?? "success";
    const message = props.message;

    const handleClose = () => {
        setVisible(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000}
                open={visible}
                onClose={handleClose}
                key={message}
            >
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
