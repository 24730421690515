import React, { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material";
import AlertDialog from "./AlertDialog";

const ImageGalleryWithDelete = ({
    images,
    selectedImage,
    showDelete,
    onDelete,
    onImageSelect,
}) => {
    const theme = useTheme();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);

    const handleImageSelect = (event, image, index) => {
        event.stopPropagation();
        if (onImageSelect) {
            onImageSelect(image, index);
        }
    };

    const handleDelete = (event, image) => {
        if (onDelete) {
            setImageToDelete(image);
            setShowDeleteConfirm(true);
        }
    };

    const handleDeleteConfirm = (positive) => {
        if (positive) {
            onDelete(imageToDelete);
        }
        setShowDeleteConfirm(false);
        setImageToDelete(null);
    };

    return (
        <div>
            <ImageList variant="quilted" cols={4} gap={4}>
                {images.map((image, index) => (
                    <ImageListItem
                        key={index}
                        style={{ position: "relative" }}
                        sx={{
                            border: selectedImage === image?.name ? 5 : 0,
                            borderColor: theme.palette.primary.main,
                            p: selectedImage === image?.name ? 0 : 0,
                        }}
                    >
                        <img
                            src={
                                image instanceof File
                                    ? URL.createObjectURL(image)
                                    : `data:image/jpeg;base64, ${image.thumbnail}`
                            }
                            alt={`Image ${index}`}
                            style={{ width: "100%", height: "100%" }}
                            onClick={(event) =>
                                handleImageSelect(event, image, index)
                            }
                        />
                        {showDelete && (
                            <ImageListItemBar
                                position="top"
                                style={{ background: "transparent" }}
                                actionIcon={
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={(e) => handleDelete(e, image)}
                                        sx={{
                                            mr: 1,
                                            mt: 1,
                                            color: "#fff",
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.6)",
                                            borderRadius: "50%",
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(0, 0, 0, 0.8)",
                                            },
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            />
                        )}
                    </ImageListItem>
                ))}
            </ImageList>
            <AlertDialog
                open={[showDeleteConfirm, setShowDeleteConfirm]}
                message="Törlöd az képet?"
                positiveButton="Törlés"
                negativeButton="Mégse"
                buttonHandler={handleDeleteConfirm}
            />
        </div>
    );
};

export default ImageGalleryWithDelete;
