import { EquipmentTypeProvider } from "./EquipmentTypeContext";
import EquipmentCategoryPageContent from "./EquipmentTypePageContent";

function EquipmentTypePage() {
    return (
        <EquipmentTypeProvider>
            <EquipmentCategoryPageContent />
        </EquipmentTypeProvider>
    );
}

export default EquipmentTypePage;
