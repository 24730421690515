import PlaceIcon from "@mui/icons-material/Place";
import FactoryIcon from "@mui/icons-material/Factory";
import GavelIcon from "@mui/icons-material/Gavel";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import {
    Box,
    Grid,
    Typography,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery,
} from "@mui/material";
// Import all your icons here
import { useNavigate } from "react-router-dom";

const options = [
    {
        label: "Eszközök",
        icon: <FitnessCenterIcon />,
        navigation: "/equipment",
    },
    { label: "Helyszínek", icon: <PlaceIcon />, navigation: "/location" },
    { label: "Üzemeltetők", icon: <HandshakeIcon />, navigation: "/operator" },
    { label: "Gyártók", icon: <FactoryIcon />, navigation: "/manufacturer" },
    {
        label: "Tanúsító szervezetek",
        icon: <GavelIcon />,
        navigation: "certificateissuer",
    },
    {
        label: "Vonatkozó szabványok",
        icon: <FilePresentIcon />,
        navigation: "standard",
    },
    {
        label: "Hatóságok",
        icon: <LocalPoliceIcon />,
        navigation: "countyauthority",
    },
    {
        label: "Szabványhoz kapcsolódó hibák",
        icon: <LocalPoliceIcon />,
        navigation: "standardissue",
    },
    {
        label: "Eszköz típusok",
        icon: <LocalPoliceIcon />,
        navigation: "equipmenttype",
    },
    {
        label: "Eszköz kategórák",
        icon: <LocalPoliceIcon />,
        navigation: "equipmentcategory",
    },
    {
        label: "Eszköz csoportok",
        icon: <LocalPoliceIcon />,
        navigation: "equipmentgroup",
    },
    {
        label: "Ellenőrzés típusok",
        icon: <LocalPoliceIcon />,
        navigation: "inspectiontype",
    },
    {
        label: "Játszótéri eszközök adattáblái",
        icon: <LocalPoliceIcon />,
        navigation: "equipmenttypeattribute",
    },
    {
        label: "Szórakoztató eszközök korlátozásai",
        icon: <LocalPoliceIcon />,
        navigation: "equipmenttyperestriction",
    },
];

const BaseDataGrid = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isXSmall = useMediaQuery(theme.breakpoints.down("xs"));

    const getColumnSize = () => {
        const sizeForXS = 12; // Full width on extra small screens
        const sizeForSM = 6; // Half width on small screens, 2 columns
        const sizeForMD = 4; // One third width on medium and larger screens, 3 columns

        return {
            xs: sizeForXS,
            sm: sizeForSM,
            md: sizeForMD,
            lg: sizeForMD,
            xl: sizeForMD,
        };
    };

    const onNavigate = (navigation) => {
        navigate(navigation);
    };

    const columnSize = getColumnSize();

    return (
        <Box>
            <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                    backgroundColor: "primary.main", // using the theme's primary color
                    color: "primary.contrastText", // ensuring text is readable on the primary color
                    padding: "8px", // adding some padding
                    borderRadius: "4px", // optional rounded corners
                }}
            >
                Alapadatok
            </Typography>
            <Grid container spacing={2}>
                {options.map((option, index) => (
                    <Grid item {...columnSize} key={index}>
                        <ListItemButton
                            onClick={() => onNavigate(option.navigation)}
                        >
                            <ListItemText primary={option.label} />
                        </ListItemButton>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default BaseDataGrid;
