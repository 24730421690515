import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useInspectionTypeContext from "./useInspectionTypeContext";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.6 },
    {
        field: "category",
        headerName: "Eszköz kategória",
        minWidth: 70,
        flex: 0.3,
        valueGetter: (params) => params.row.category.name,
    },
    {
        field: "default",
        headerName: "",
        type: "boolean",
        minWidth: 70,
        flex: 0.1,
    },
];

function InspectionTypeTable() {
    const { inspectionTypes, setSelectedInspectionType } =
        useInspectionTypeContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedInspectionType(id);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={inspectionTypes}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default InspectionTypeTable;
