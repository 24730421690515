import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useAdminTemplateContext from "./useAdminTemplateContext";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DOCUMENT_TEMPLATE_TYPES } from "../../../utils/DocumentTemplateType";
import FileUpload from "../../common/FileUpload";

function AdminTemplateEditDialog() {
    const {
        data,
        handleChange,
        showDialog,
        setShowDialog,
        save,
        errors,
        hasError,
    } = useAdminTemplateContext();

    const handleClose = (e) => {
        switch (e.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                setShowDialog(false);
                break;
        }
    };

    return (
        <Dialog disableEscapeKeyDown open={showDialog} onClose={handleClose}>
            <DialogTitle>Új dokumentum sablon</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{ width: "100%", my: 1 }}>
                    <InputLabel id="demo-simple-select-label">
                        Sablon típus
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="type"
                        label="Sablon típus"
                        onChange={handleChange}
                        value={data.type}
                        error={errors.type != null}
                    >
                        {DOCUMENT_TEMPLATE_TYPES.map((templateType) => (
                            <MenuItem
                                key={templateType}
                                value={templateType.code}
                            >
                                {templateType.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Megjegyzés"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.description}
                    error={errors.description != null}
                    name="description"
                />
                <FileUpload
                    data={data}
                    name="template"
                    fileTypes=".docx"
                    handleChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdminTemplateEditDialog;
