import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useContext, useReducer } from "react";
import { appbarReducer, APPBAR_ACTION_TYPES } from "./appbarReducer";
import { LOGIN_INITIAL_STATE } from "../login/loginReducer";
import useLogout from "../../hooks/useLogout";
import { FormControlLabel, Switch } from "@mui/material";
import ColorModeContext from "../../context/ToggleColorMode";

const PAGE_NAME = "JÁTSZÓTÉR ŐR";
const profile_menu_items = {
    PROFILE: "Profil",
    ADMIN: "Admin",
    LOGOUT: "Kijelentkezés",
};
const pages = [];
const settings = [profile_menu_items.ADMIN, profile_menu_items.LOGOUT];

const ResponsiveAppBar = () => {
    const colorMode = useContext(ColorModeContext);

    const navigate = useNavigate();
    const logout = useLogout();

    const [state, dispatch] = useReducer(appbarReducer, LOGIN_INITIAL_STATE);
    const { auth } = useAuth();
    const isMenuVisible = auth?.accessToken ? true : false;

    const signOut = async () => {
        await logout();
        navigate("/login");
    };

    const handleOpenNavMenu = (event) => {
        dispatch({
            type: APPBAR_ACTION_TYPES.OPEN_NAV_MENU,
            payload: event.currentTarget,
        });
    };

    const handleOpenUserMenu = (event) => {
        dispatch({
            type: APPBAR_ACTION_TYPES.OPEN_USER_MENU,
            payload: event.currentTarget,
        });
    };

    const handleCloseNavMenu = () => {
        dispatch({ type: APPBAR_ACTION_TYPES.CLOSE_NAV_MENU, payload: null });
    };

    const handleCloseUserMenu = (setting) => {
        dispatch({ type: APPBAR_ACTION_TYPES.CLOSE_USER_MENU, payload: null });
        switch (setting) {
            case profile_menu_items.ADMIN:
                navigate("/admin");
                break;
            case profile_menu_items.LOGOUT:
                signOut();
                break;
            default:
                break;
        }
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        {PAGE_NAME}
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <Menu
                            id="menu-appbar"
                            anchorEl={state.anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(state.anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign="center">
                                        {page}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        {PAGE_NAME}
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page}
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    {isMenuVisible && (
                        <Box sx={{ flexGrow: 0 }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        defaultChecked={true}
                                        color="default"
                                        onChange={colorMode.toggleColorMode}
                                    />
                                }
                                label="Dark mode"
                            />
                            <Tooltip title="Open settings">
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0 }}
                                >
                                    <Avatar src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={state.anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(state.anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem
                                        key={setting}
                                        onClick={() =>
                                            handleCloseUserMenu(setting)
                                        }
                                    >
                                        <Typography textAlign="center">
                                            {setting}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
