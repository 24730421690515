import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { MANUFACTURER_URL } from "./Url";

export function useManufacturerApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getManufacturers(onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${MANUFACTURER_URL}/list`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getPrioriyManufacturers(onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${MANUFACTURER_URL}/list/priority`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveManufacturer(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            if (data.id === null) {
                await axiosPrivate
                    .post(`${MANUFACTURER_URL}`, JSON.stringify(data), {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            } else {
                await axiosPrivate
                    .put(
                        `${MANUFACTURER_URL}/${data.id}`,
                        JSON.stringify(data),
                        {
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true,
                        }
                    )
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function uploadManufacturerCsv(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        const formData = new FormData();
        formData.append("File", data);

        try {
            await axiosPrivate
                .post(`${MANUFACTURER_URL}/list`, formData, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteManufacturer(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${MANUFACTURER_URL}/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return {
        getManufacturers,
        getPrioriyManufacturers,
        saveManufacturer,
        deleteManufacturer,
        uploadManufacturerCsv,
    };
}
