import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useCertificateIssuerContext from "./useCertificateIssuerContext";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.2 },
    { field: "city", headerName: "Város", minWidth: 130, flex: 0.2 },
    { field: "zip", headerName: "Ir.szám", minWidth: 130, flex: 0.2 },
    { field: "address", headerName: "Cím", minWidth: 130, flex: 0.2 },
];

function CertificateIssuerTable() {
    const { certificateIssuers, selectCertificateIssuer } =
        useCertificateIssuerContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        selectCertificateIssuer(id);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={certificateIssuers}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default CertificateIssuerTable;
