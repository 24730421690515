import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { STANDARDISSUE_URL } from "./Url";

export function useStandardIssueApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getStandardIssuesByStandard(standardId, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${STANDARDISSUE_URL}/list/standard/${standardId}`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveStandardIssue(
        standardIssue,
        selectedStandard,
        onSuccess
    ) {
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                id: standardIssue.id,
                standardId: selectedStandard.id,
                standardSection: standardIssue.standardSection,
                description: standardIssue.description,
                dangerous: standardIssue.dangerous,
            };
            if (request.id === null) {
                await axiosPrivate
                    .post(`${STANDARDISSUE_URL}`, JSON.stringify(request), {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            } else {
                await axiosPrivate
                    .put(
                        `${STANDARDISSUE_URL}/${request.id}`,
                        JSON.stringify(request),
                        {
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true,
                        }
                    )
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteStandardIssue(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${STANDARDISSUE_URL}/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getStandardIssuesByEquipmentType(
        equipmentTypeId,
        onSuccess
    ) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(
                    `${STANDARDISSUE_URL}/list/equipmenttype/${equipmentTypeId}`,
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return {
        getStandardIssuesByStandard,
        saveStandardIssue,
        deleteStandardIssue,
        getStandardIssuesByEquipmentType,
    };
}
