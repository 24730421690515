import { ManufacturerProvider } from "./ManufacturerContext";
import ManufacturerPageContent from "./ManufacturerPageContent";

function ManufacturerPage() {
    return (
        <ManufacturerProvider>
            <ManufacturerPageContent />
        </ManufacturerProvider>
    );
}

export default ManufacturerPage;
