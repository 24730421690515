import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useLocationContext from "./useLocationContext";
import LocationEditDialogContent from "./LocationEditDialogContent";

function LocationEditDialog() {
    const { data, showDialog, setShowDialog, save, hasError } =
        useLocationContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title = data.id === null ? "Új helyszín" : "Helyszín szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <LocationEditDialogContent />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LocationEditDialog;
