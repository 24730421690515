import { PlaygroundReportEquipmentsProvider } from "./PlaygroundReportEquipmentsContext";
import PlaygroundReportEquipmentsPageContent from "./PlaygroundReportEquipmentsPageContent";

const PlaygroundReportEquipmentsPage = () => {
    return (
        <PlaygroundReportEquipmentsProvider>
            <PlaygroundReportEquipmentsPageContent />
        </PlaygroundReportEquipmentsProvider>
    );
};

export default PlaygroundReportEquipmentsPage;
