import config_development from "../../config_development.json";
import config_production from "../../config_production.json";

function getConfig(key) {
    const config =
        process.env.NODE_ENV === "production"
            ? config_production
            : config_development;

    const keys = key.split(".");
    return getByKeys(config, keys);
}

function getByKeys(config, keys) {
    if (keys.length > 1) {
        return getByKeys(config[keys[0]], keys.slice(1));
    } else {
        return config[keys[0]];
    }
}

export default getConfig;
