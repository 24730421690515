import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles, queryParams }) => {
    const { auth } = useAuth();
    const location = useLocation();

    return allowedRoles
        .map((allowedRole) => allowedRole.code)
        ?.includes(auth?.role) ? (
        <Outlet />
    ) : auth?.email ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
        <Navigate to="/login" state={{ from: location, queryParams }} replace />
    );
};

export default RequireAuth;
