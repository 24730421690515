import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { useEquipmentApi } from "../../../api/equipmentApi";
import { useEquipmentCategoryApi } from "../../../api/equipmentCategoryApi";
import { EQUIPMENT_CATEGORY_URL } from "../../../api/Url";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useFullScreenProgressBarContext from "../../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../../common/generalerrorsnackbar/useGeneralErrorContext";

const emptyEquipmentCategory = {
    id: null,
    name: null,
    description: null,
    csv: null,
};

const emptyErrors = {
    name: null,
    csv: null,
};

const EquipmentCategoryContext = createContext({});

export const EquipmentCategoryProvider = ({ children }) => {
    const {
        getEquipmentCategories,
        saveEquipmentCategory,
        uploadEquipmentCategoryCsv,
        deleteEquipmentCategory,
    } = useEquipmentCategoryApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showTableLoading, setShowTableLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [equipmentCategories, setEquipmentCategories] = useState([]);
    const [errors, setErrors] = useState(emptyErrors);
    const [data, setData] = useState({ emptyEquipmentCategory });
    const hasError = !data.name || errors.name;
    const showTableEditButtons = data.id == null ? false : true;

    function validate(name, value) {
        switch (name) {
            case "name": {
                break;
            }
            case "description": {
                break;
            }
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        validate(name, value);

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchEquipmentCategory();
    }, []);

    function setSelectedEquipmentCategory(id) {
        if (id != null) {
            const equipmentCategory = equipmentCategories.find(
                (equipmentCategory) => equipmentCategory.id === id
            );
            setData(equipmentCategory);
        } else {
            setData(emptyEquipmentCategory);
        }
    }

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    function fetchEquipmentCategory() {
        getEquipmentCategories((response) => {
            setEquipmentCategories(response.data.equipmentCategories);
        });
    }

    function save() {
        saveEquipmentCategory(data, (respons) => {
            setData(emptyEquipmentCategory);
            fetchEquipmentCategory();
        });
    }

    function uploadCsv() {
        uploadEquipmentCategoryCsv(data.csv, (response) => {
            fetchEquipmentCategory();
        });
    }

    function deleteEquipmentCat() {
        deleteEquipmentCategory(data.id, (response) => {
            fetchEquipmentCategory();
        });
    }

    return (
        <EquipmentCategoryContext.Provider
            value={{
                data,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                equipmentCategories,
                setEquipmentCategories,
                setSelectedEquipmentCategory,
                errors,
                hasError,
                handleChange,
                save,
                deleteEquipmentCat,
                uploadCsv,
                showTableEditButtons,
            }}
        >
            {children}
        </EquipmentCategoryContext.Provider>
    );
};

export default EquipmentCategoryContext;
