import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

// Styles for the container
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
    textAlign: "left", // Ensure all text is left-aligned
}));

// Styled label at the top
const Label = styled(Typography)({
    fontWeight: "bold",
    fontSize: "1.5em",
    marginBottom: "0.5em",
});

// Styled item label
const ItemLabel = styled(Typography)({
    fontSize: "1em",
    display: "inline",
});

// Styled item value which is bold and primary color
const ItemValue = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "inline",
}));

// The InfoPaper component
const UserPerformanceCard = ({ name, list }) => {
    const theme = useTheme(); // Using the theme to access the primary color

    return (
        <StyledPaper elevation={3}>
            <Label component="h2">{name}</Label>
            {list.map(({ label, value }, index) => (
                <div key={index} style={{ marginBottom: theme.spacing(0.5) }}>
                    <ItemLabel>{label}:</ItemLabel>{" "}
                    <ItemValue>{value}</ItemValue>
                </div>
            ))}
        </StyledPaper>
    );
};

export default UserPerformanceCard;
