import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { STANDARD_URL } from "./Url";

export function useStandardApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getStandards(onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${STANDARD_URL}/list`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getStandardsByEquipmentType(equipmentTypeId, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${STANDARD_URL}/list/${equipmentTypeId}`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveStandard(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        const formData = new FormData();
        formData.append("File", data.document);
        formData.append("code", data.code);
        formData.append("name", data.name);
        formData.append("section", data.section);
        const types = data.types.map((type) => type.id);
        formData.append("types", types);

        try {
            if (data.id != null) {
                await axiosPrivate
                    .put(`${STANDARD_URL}/${data.id}`, formData, {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            } else {
                await axiosPrivate
                    .post(`${STANDARD_URL}`, formData, {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteStandard(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${STANDARD_URL}/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function downloadStandard(id) {
        try {
            const response = await axiosPrivate.get(
                `${STANDARD_URL}/${id}/download`,
                {
                    responseType: "blob",
                    withCredentials: true,
                }
            );
            const fileURL = URL.createObjectURL(response.data);
            window.open(fileURL);
        } catch (error) {
            handleGeneralError(error);
        }
    }

    return {
        getStandards,
        getStandardsByEquipmentType,
        saveStandard,
        deleteStandard,
        downloadStandard,
    };
}
