import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import OperatorEditDialog from "./OperatorEditDialog";
import OperatorTable from "./OperatorTable";
import useOperatorContext from "./useOperatorContext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "../../common/AlertDialog";
import PageTitleWithBackButton from "../../common/PageTitleWithBackButton";

function OperatorPageContent() {
    const {
        handleChange,
        setShowDialog,
        setSelectedOperator,
        showTableEditButtons,
        deleteOperat,
        showDeleteConfirm,
        setShowDeleteConfirm,
        uploadCsv,
        fetchOperators,
    } = useOperatorContext();

    const handleClickOpen = (e) => {
        switch (e.target.name) {
            case "new": {
                setSelectedOperator(null);
                setShowDialog(true);
                break;
            }
            case "edit": {
                setShowDialog(true);
                break;
            }
            case "delete": {
                setShowDeleteConfirm(true);
                break;
            }
            default:
                break;
        }
    };

    const handleDeleteConfirm = (positive) => {
        if (positive) {
            deleteOperat();
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <PageTitleWithBackButton title="Üzemeltetők" />
            <Stack direction="row" spacing={2} m={2}>
                <Button
                    variant="contained"
                    name="new"
                    onClick={handleClickOpen}
                >
                    Új üzemeltető
                </Button>
                {showTableEditButtons && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="edit"
                            startIcon={<EditIcon />}
                            onClick={handleClickOpen}
                        >
                            Szerkesztés
                        </Button>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="delete"
                            startIcon={<DeleteIcon />}
                            onClick={handleClickOpen}
                        >
                            Törlés
                        </Button>
                    </Box>
                )}
                <input
                    type="file"
                    name="csv"
                    accept=".csv"
                    onChange={handleChange}
                />
                <Button onClick={uploadCsv}>Upload</Button>
            </Stack>
            <OperatorTable />
            <OperatorEditDialog
                onClose={() => {
                    fetchOperators();
                }}
            />
            <AlertDialog
                open={[showDeleteConfirm, setShowDeleteConfirm]}
                message="Törlöd az üzemeltetőt?"
                positiveButton="Törlés"
                negativeButton="Mégse"
                buttonHandler={handleDeleteConfirm}
            />
        </Box>
    );
}

export default OperatorPageContent;
