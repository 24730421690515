import { IconButton, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const PageTitleWithBackButton = (props) => {
    const navigate = useNavigate();
    const { title } = props;
    return (
        <Stack direction="row" spacing={2}>
            <IconButton color="primary" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" gutterBottom>
                {title}
            </Typography>
        </Stack>
    );
};

export default PageTitleWithBackButton;
