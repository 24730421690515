import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";

export function SplitButton({
    children,
    sx,
    onClick,
    menuItems,
    onMenuItemClicked,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = () => {
        if (onClick) {
            onClick();
        }
    };

    const handleDropdownClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (item) => {
        setAnchorEl(null);
        if (onMenuItemClicked) {
            onMenuItemClicked(item);
        }
    };

    return (
        <Box sx={{ display: "flex", width: "100%", ...sx }}>
            <Button
                sx={{ flexGrow: 1 }}
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
            >
                {children}
            </Button>
            <IconButton
                color="primary"
                size="small"
                onClick={handleDropdownClick}
                style={{ marginLeft: "-1px" }}
            >
                <ArrowDropDownIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose(null)}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={index} onClick={() => handleClose(item)}>
                        {item}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
