import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Outlet } from "react-router-dom";
import { ToggleColorMode } from "../../context/ToggleColorMode";
import ResponsiveAppBar from "../appbar/ResponsiveAppBar";
import FullScreenProgressBar from "../common/fullscreenprogressbar/FullScreenProgressBar";
import { FullScreenProgressBarProvider } from "../common/fullscreenprogressbar/FullScreenProgressBarContext";
import { GeneralErrorProvider } from "../common/generalerrorsnackbar/GeneralErrorContext";
import GeneralErrorSnackBar from "../common/generalerrorsnackbar/GeneralErrorSnackbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Layout = () => {
    return (
        <ToggleColorMode>
            <CssBaseline />
            <GeneralErrorProvider>
                <FullScreenProgressBarProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <main className="App">
                            <ResponsiveAppBar />
                            <div style={{ maxWidth: "1200px", margin: "auto" }}>
                                <Outlet />
                            </div>
                            <FullScreenProgressBar />
                            <GeneralErrorSnackBar />
                        </main>
                    </LocalizationProvider>
                </FullScreenProgressBarProvider>
            </GeneralErrorProvider>
        </ToggleColorMode>
    );
};

export default Layout;
