import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useOperatorContext from "./useOperatorContext";
import OperatorEditDialogContent from "./OperatorEditDialogContent";
import { useOperatorApi } from "../../../api/operatorApi";

function OperatorEditDialog({ onClose }) {
    const { data, showDialog, setShowDialog, hasError } = useOperatorContext();

    const { saveOperator } = useOperatorApi();

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                saveOperator(data, (response) => {
                    onClose(response);
                });
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title = data.id == null ? "Új üzemeltető" : "Üzemeltető szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown={true}
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <OperatorEditDialogContent />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default OperatorEditDialog;
