import { EquipmentTypeAttributeProvider } from "./EquipmentTypeAttributeContext";
import EquipmentTypeAttributePageContent from "./EquipmentTypeAttributePageContent";

function EquipmentTypeAttributePage() {
    return (
        <EquipmentTypeAttributeProvider>
            <EquipmentTypeAttributePageContent />
        </EquipmentTypeAttributeProvider>
    );
}

export default EquipmentTypeAttributePage;
