import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import StandardIssueEditDialog from "./StandardIssueEditDialog";
import useStandardIssueContext from "./useStandardIssueContext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "../../common/AlertDialog";
import PageTitleWithBackButton from "../../common/PageTitleWithBackButton";
import StandardIssueTable from "./StandardIssueTable";

function StandardIssuePageContent() {
    const {
        handleChange,
        standards,
        selectedStandard,
        setShowDialog,
        setSelectedStandardIssue,
        showTableEditButtons,
        deleteStandardIssu,
        showDeleteConfirm,
        setShowDeleteConfirm,
    } = useStandardIssueContext();

    const handleClickOpen = (e) => {
        switch (e.target.name) {
            case "new": {
                setSelectedStandardIssue(null);
                setShowDialog(true);
                break;
            }
            case "edit": {
                setShowDialog(true);
                break;
            }
            case "delete": {
                setShowDeleteConfirm(true);
                break;
            }
            default:
                break;
        }
    };

    const getOptionLabel = (option) => {
        let label = option.name;
        if (option.section && option.section !== "") {
            label = label.concat(" - ", `${option.section}`);
        }
        return label;
    };

    const handleDeleteConfirm = (positive) => {
        if (positive) {
            deleteStandardIssu();
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <PageTitleWithBackButton title="Szabványokhoz kapcsolódó hibák" />
            <Stack direction="row" spacing={2} m={2}>
                <Button
                    variant="contained"
                    name="new"
                    disabled={selectedStandard == null}
                    onClick={handleClickOpen}
                >
                    Új elem
                </Button>
                {showTableEditButtons && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="edit"
                            startIcon={<EditIcon />}
                            onClick={handleClickOpen}
                        >
                            Szerkesztés
                        </Button>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="delete"
                            startIcon={<DeleteIcon />}
                            onClick={handleClickOpen}
                        >
                            Törlés
                        </Button>
                    </Box>
                )}
            </Stack>
            <Autocomplete
                sx={{ width: "100%", my: 2 }}
                id="free-solo-demo"
                name="standard"
                value={selectedStandard}
                onChange={(event, newInputValue) => {
                    if (event != null) {
                        event.target.type = "autocomplete";
                        event.target.name = "type";
                        event.target.selectedOption = newInputValue;
                        handleChange(event);
                    }
                }}
                options={standards}
                getOptionLabel={(option) => getOptionLabel(option)}
                renderInput={(params) => (
                    <TextField {...params} label="Szabvány" />
                )}
            />
            <StandardIssueTable />
            <StandardIssueEditDialog />
            <AlertDialog
                open={[showDeleteConfirm, setShowDeleteConfirm]}
                message="Törlöd az hibát?"
                positiveButton="Törlés"
                negativeButton="Mégse"
                buttonHandler={handleDeleteConfirm}
            />
        </Box>
    );
}

export default StandardIssuePageContent;
