import { Button, Card, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { USER_URL } from "../../api/Url";
import { validateEmail } from "../../utils/validators";
import useGeneralErrorContext from "../common/generalerrorsnackbar/useGeneralErrorContext";
import CustomSnackbar from "../common/CustomSnackBar";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const { handleGeneralError } = useGeneralErrorContext();
    const [snackbar, setSnackbar] = useState(false);

    const setInputValue = (e) => {
        const value = e.target.value;
        const emailValid = validateEmail(value);
        setEmailError(!emailValid ? "Érvénytelen email cím" : "");
        setEmail(value);
    };

    const onLoginClick = () => {
        navigate("/login", { replace: true });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(
                `${USER_URL}/forgotpassword`,
                JSON.stringify({
                    email: email,
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );
            setSnackbar(true);
        } catch (error) {
            handleGeneralError(error);
        }
    };

    const hasError = emailError !== "";

    return (
        <section>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Card component="span" sx={{ minWidth: 275, p: 2 }}>
                    <h1>Elfelejtett jelszó</h1>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            sx={{ width: "100%", my: 1 }}
                            id="outlined-basic email"
                            label="Email"
                            variant="outlined"
                            autoFocus
                            type="text"
                            autoComplete="off"
                            onChange={setInputValue}
                            helperText={emailError}
                            error={emailError}
                            name="email"
                            required
                        />
                        <br />
                        <Stack direction="row" spacing={2} m={2}>
                            <Button variant="text" onClick={onLoginClick}>
                                Bejelentkezés
                            </Button>
                            <Button
                                sx={{ mt: 2 }}
                                enabled={!hasError}
                                variant="contained"
                                type="submit"
                            >
                                Új jelszó igénylés
                            </Button>
                        </Stack>
                    </form>
                </Card>
            </Box>
            <CustomSnackbar
                message="Sikeres jelszó visszaállítás igénylés"
                visible={[snackbar, setSnackbar]}
            />
        </section>
    );
};

export default ForgotPassword;
