import { InspectionTypeProvider } from "./InspectionTypeContext";
import InspectionCategoryPageContent from "./InspectionTypePageContent";

function InspectionTypePage() {
    return (
        <InspectionTypeProvider>
            <InspectionCategoryPageContent />
        </InspectionTypeProvider>
    );
}

export default InspectionTypePage;
