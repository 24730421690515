import { ReportProvider } from "./ReportContext";
import PlaygroundReportContent from "./ReportContent";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ReportImagesProvider } from "./ReportImagesContext";

const PlaygroundReport = () => {
    let params = useParams();

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    return (
        <ReportProvider reportId={params.reportId}>
            <ReportImagesProvider>
                <PlaygroundReportContent />
            </ReportImagesProvider>
        </ReportProvider>
    );
};

export default PlaygroundReport;
