import { Button, Stack } from "@mui/material";
import { useTestApi } from "../../../api/testApi";

function AdminTestPage() {
    const { callTestFunction } = useTestApi();

    return (
        <Stack spacing={2} sx={{ mt: 2, width: "300px" }}>
            <Button
                variant="contained"
                onClick={() => callTestFunction("databaseBackup")}
            >
                Test Database Backup
            </Button>
            <Button
                variant="contained"
                onClick={() => callTestFunction("updatedContactEmails")}
            >
                Updated operator contact emails
            </Button>
        </Stack>
    );
}

export default AdminTestPage;
