import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { OPERATOR_URL } from "./Url";

export function useOperatorApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getOperators(onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            const response = await axiosPrivate
                .get(`${OPERATOR_URL}/list`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveOperator(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        let response;
        try {
            if (data.id === null) {
                response = await axiosPrivate
                    .post(`${OPERATOR_URL}`, JSON.stringify(data), {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            } else {
                response = await axiosPrivate
                    .put(`${OPERATOR_URL}/${data.id}`, JSON.stringify(data), {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return response.data.operator;
    }

    async function uploadOperatorCsv(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        const formData = new FormData();
        formData.append("File", data);

        try {
            await axiosPrivate
                .post(`${OPERATOR_URL}/list`, formData, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteOperator(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${OPERATOR_URL}/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return { getOperators, saveOperator, uploadOperatorCsv, deleteOperator };
}
