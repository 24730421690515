import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const DROPBOX_CODE_URL = "image/code";

const DropBoxCode = () => {
    const [searchParams] = useSearchParams();
    const dropboxCode = searchParams.get("code");
    const axiosPrivate = useAxiosPrivate();
    const [success, setSuccess] = useState(false);
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    console.log(`dropboxCode: ${dropboxCode}`);

    const sendCode = async (e) => {
        try {
            await axiosPrivate.post(
                DROPBOX_CODE_URL,
                JSON.stringify({ code: dropboxCode }),
                {
                    withCredentials: true,
                }
            );
            setSuccess(true);
        } catch (error) {
            if (!error.response) {
                console.log("No Server Response");
            } else if (error.response?.status === 400) {
                console.log("Missing Username or Password");
            } else if (error.response?.status === 401) {
                console.log("Unauthorized");
            } else {
                console.log("Login failed");
            }
        }
    };

    useEffect(() => {
        sendCode();
    }, []);

    return success ? (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Box>
                <Typography variant="h5">
                    Sikeres Dropbox authentikáció
                </Typography>
                <Button variant="contained" onClick={() => window.close()}>
                    Bezárás
                </Button>
            </Box>
        </Box>
    ) : (
        <p>Dropbox authentikáció...</p>
    );
};

export default DropBoxCode;
