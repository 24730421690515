import { useParams } from "react-router-dom";
import { ReportBaseDataProvider } from "./ReportBaseDataContext";
import ReportBaseDataPageContent from "./ReportBaseDataPageContent";

const ReportBaseDataPage = () => {
    let params = useParams();
    const isReturn = params.option === "return";
    return (
        <ReportBaseDataProvider isReturn={isReturn}>
            <ReportBaseDataPageContent isReturn={isReturn} />
        </ReportBaseDataProvider>
    );
};

export default ReportBaseDataPage;
