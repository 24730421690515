import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useOperatorContext from "./useOperatorContext";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.3 },
    { field: "city", headerName: "Város", minWidth: 130, flex: 0.1 },
    { field: "zip", headerName: "Ir.szám", minWidth: 130, flex: 0.1 },
    { field: "address", headerName: "Cím", minWidth: 130, flex: 0.2 },
    {
        field: "companyRegistrationNumber",
        headerName: "Cégjegyzékszám/Adószám",
        minWidth: 130,
        flex: 0.3,
    },
];

function OperatorTable() {
    const { operators, setSelectedOperator } = useOperatorContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedOperator(id);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={operators}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default OperatorTable;
