import dayjs from "dayjs";
import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { useParams } from "react-router-dom";
import { REPORT_URL } from "../../../api/Url";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useFullScreenProgressBarContext from "../../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../../common/generalerrorsnackbar/useGeneralErrorContext";
import useReportContext from "../useReportContext";
import { getCurrentYear } from "../../../utils/DateUtils";

const emptyErrors = {
    shortName: null,
    csv: null,
};

const ReportBaseDataContext = createContext({});

export const ReportBaseDataProvider = ({ children }) => {
    let params = useParams();
    const isReturn = params.option === "return";
    const { report, setNextEnabled } = useReportContext();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedYearForReport, setSelectedYearForReport] = useState(
        getCurrentYear()
    );
    const [selectedYearForList, setSelectedYearForList] = useState(
        getCurrentYear()
    );

    const [errors, setErrors] = useState(emptyErrors);

    const [data, setData] = useState(report.baseData);
    if (report.baseData.id !== data.id) {
        setData(report.baseData);
    }

    useEffect(() => {
        setData(report.baseData);
    }, [report.baseData.number]);

    const handleDate = (date) => {
        setData((prevData) => ({
            ...prevData,
            date: date,
        }));
    };

    function validate(name, value) {
        switch (name) {
            case "number": {
                break;
            }
            case "shortName": {
                break;
            }
            case "date": {
                break;
            }
            default:
                break;
        }
    }

    useEffect(() => {
        const hasError =
            !data.shortName ||
            !data.number ||
            !data.date ||
            data.shortName?.trim() === "" ||
            data.number === "";
        setNextEnabled(!hasError);
    }, [data, setData]);

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        validate(name, value);

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    const saveReport = async () => {
        let response;
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                id: data.id,
                reportType: report.baseData.reportType.code,
                shortName: data.shortName,
                number: data.number,
                year: data.year ?? selectedYearForReport,
                date: dayjs(data.date).valueOf(),
                trelloCardId: data.trelloCardId,
            };
            if (isReturn) {
                response = await axiosPrivate.post(
                    `${REPORT_URL}/${data.id}`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
            } else if (data.id == null) {
                response = await axiosPrivate.post(
                    `${REPORT_URL}`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
            } else {
                response = await axiosPrivate.put(
                    `${REPORT_URL}/${data.id}`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return response.data.report;
    };

    return (
        <ReportBaseDataContext.Provider
            value={{
                data,
                showDialog,
                setShowDialog,
                errors,
                handleChange,
                handleDate,
                saveReport,
                selectedYearForList,
                setSelectedYearForList,
                selectedYearForReport,
                setSelectedYearForReport,
            }}
        >
            {children}
        </ReportBaseDataContext.Provider>
    );
};

export default ReportBaseDataContext;
