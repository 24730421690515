import { axiosPrivate } from "./axios";

const fetchCounties = async (e) => {
    try {
        const countiesString = sessionStorage.getItem("counties");
        let counties;
        if (countiesString) {
            counties = JSON.parse(countiesString);
        } else {
            const response = await axiosPrivate.get(`county/list`, {
                withCredentials: true,
            });
            counties = response.data.counties;
            sessionStorage.setItem("counties", JSON.stringify(counties));
        }
        return counties;
    } catch (error) {
        return [];
    } finally {
    }
};

export default fetchCounties;
