import { CertificateIssuerProvider } from "./CertificateIssuerContext";
import CertificateIssuerPageContent from "./CertificateIssuerPageContent";

function CertificateIssuerPage() {
    return (
        <CertificateIssuerProvider>
            <CertificateIssuerPageContent />
        </CertificateIssuerProvider>
    );
}

export default CertificateIssuerPage;
