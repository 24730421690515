import {
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import CustomDropzone from "../../common/CustomDropzone";
import CustomSnackbar from "../../common/CustomSnackBar";
import PrevNextButtons from "../PrevNextButtons";
import InfoIcon from "@mui/icons-material/Info";
import useReportContext from "../useReportContext";
import { useTemplateApi } from "../../../api/templateApi";
import { useEffect, useState } from "react";
import { useReportApi } from "../../../api/reportApi";
import { SplitButton } from "../../common/SplitButton";

const generateMenuItems = ["Eszközönkénti generálás"];
const PlaygroundReportGeneratePage = () => {
    const navigate = useNavigate();
    const { report } = useReportContext();
    const { generateReport } = useReportApi();
    const { getTemplatesByReportId } = useTemplateApi();
    const [templates, setTemplates] = useState([]);
    const [reportDate, setReportDate] = useState(Date());
    const [sendEmail, setSendEmail] = useState(true);
    const [email, setEmail] = useState("iroda@jatszoteror.hu");
    const [emailBody, setEmailBody] = useState("");
    const [download, setDownload] = useState(true);
    const [emailSnackbar, setEmailSnackbar] = useState(false);
    const [uploadedSnackbar, setUploadedSnackbar] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        if (report.baseData.id) {
            fetchTemplates();
        }
    }, [report]);

    function fetchTemplates() {
        getTemplatesByReportId(report.baseData.id, (response) => {
            setTemplates(response.data.templates);
        });
    }

    function onGenerateItemClicked(item) {
        switch (item) {
            case generateMenuItems[0]:
                generate(true);
                break;
            default:
                break;
        }
    }

    function generate(separatedByEquipments = false) {
        generateReport(
            report.baseData.id,
            templates,
            reportDate,
            download,
            sendEmail,
            email,
            emailBody,
            selectedFiles,
            separatedByEquipments,
            (response) => {
                if (separatedByEquipments) {
                    setUploadedSnackbar(true);
                } else if (sendEmail) {
                    setEmailSnackbar(true);
                }
            }
        );
    }

    return (
        <Stack
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="top"
            columns={12}
            sx={{ mt: 8 }}
        >
            <Card sx={{ minWidth: 275 }} variant="outlined">
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="top"
                    sx={{ mt: 0, mb: 2 }}
                >
                    <Stack>
                        <FormControlLabel
                            label="Letöltés"
                            control={
                                <Checkbox
                                    defaultChecked
                                    name="download"
                                    checked={download}
                                    onChange={(e) =>
                                        setDownload(e.target.checked)
                                    }
                                />
                            }
                        />
                        <FormControlLabel
                            label="Email küldés"
                            control={
                                <Checkbox
                                    defaultChecked
                                    name="email"
                                    checked={sendEmail}
                                    onChange={(e) =>
                                        setSendEmail(e.target.checked)
                                    }
                                />
                            }
                        />
                        <Stack container direction="row" alignItems="center">
                            <TextField
                                sx={{ ml: 4, mt: 2, width: 300 }}
                                id="outlined-basic"
                                label="Email cím(ek)"
                                name="email"
                                disabled={!sendEmail}
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="text"
                            />
                            <Tooltip
                                sx={{ ml: 1, mt: 2 }}
                                title="Több email cím esetén vesszővel elválasztva"
                            >
                                <InfoIcon></InfoIcon>
                            </Tooltip>
                        </Stack>
                        <TextField
                            sx={{ ml: 4, mt: 2, width: 300 }}
                            id="outlined-basic"
                            label="Email szövege"
                            name="emailBody"
                            disabled={!sendEmail}
                            variant="outlined"
                            value={emailBody}
                            onChange={(e) => setEmailBody(e.target.value)}
                            type="text"
                        />
                        <Box sx={{ ml: 4, mt: 2, width: 300 }}>
                            <CustomDropzone
                                disabled={!sendEmail}
                                listFiles={true}
                                message="Dobj ide fájlokat vagy klikk"
                                onFileListChange={(files) => {
                                    console.log(files);
                                    setSelectedFiles(files);
                                }}
                            />
                        </Box>
                    </Stack>
                    <Stack sx={{ mt: 8, mb: 2 }}>
                        <DesktopDatePicker
                            name="date"
                            label="Jegyzőkönyv készítés dátuma"
                            inputFormat="YYYY.MM.DD"
                            value={reportDate}
                            onChange={setReportDate}
                            maxDate={Date()}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <SplitButton
                            sx={{ mt: 2 }}
                            onClick={() => generate()}
                            menuItems={generateMenuItems}
                            onMenuItemClicked={(item) => {
                                onGenerateItemClicked(item);
                            }}
                        >
                            Generálás
                        </SplitButton>
                    </Stack>
                </Grid>
            </Card>
            <CustomSnackbar
                message={`Jegyzőkönyv elküldve: ${email}`}
                visible={[emailSnackbar, setEmailSnackbar]}
            />
            <CustomSnackbar
                message={`Eszközönként generált jegyzőkönyvek feltöltve!`}
                visible={[uploadedSnackbar, setUploadedSnackbar]}
            />
            <PrevNextButtons
                nextLabel="Vissza a kezdőoldalra"
                nextFunction={() => {
                    navigate("/");
                    return true;
                }}
            />
        </Stack>
    );
};

export default PlaygroundReportGeneratePage;
