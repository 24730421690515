import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { useUserApi } from "../../../api/userApi";
import { validateEmail } from "../../../utils/validators";

const emptyUser = {
    id: null,
    email: "",
    firstName: "",
    lastName: "",
    role: 0,
    password: "",
    active: true,
    reportVerifier: null,
};

const emptyErrors = {
    email: null,
    firstName: null,
    lastName: null,
    role: null,
};

const AdminUserContext = createContext({});

export const AdminUserProvider = ({ children }) => {
    const {
        fetchUsers,
        saveUser,
        deleteUser,
        requestPassword,
        downloadSignature,
    } = useUserApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [users, setUsers] = useState([]);
    const [errors, setErrors] = useState(emptyErrors);
    const [data, setData] = useState({ emptyUser });

    const hasError =
        !data.email ||
        !data.firstName ||
        !data.lastName ||
        !data.role ||
        errors.email ||
        errors.firstName ||
        errors.lastName ||
        errors.role;

    const showTableEditButtons = data.id != null;

    function validate(name, value) {
        switch (name) {
            case "email": {
                if (validateEmail(value) == null) {
                    setErrors({
                        ...errors,
                        email: "Érvénytelen email cím",
                    });
                } else {
                    setErrors({
                        ...errors,
                        email: null,
                    });
                }
                break;
            }
            case "firstName": {
                break;
            }
            case "lasttName": {
                break;
            }
            case "role": {
                break;
            }
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }
        validate(name, value);

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetch();
    }, []);

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    function selectUser(id) {
        if (id != null) {
            const user = users.find((user) => user.id === id);
            setData(user);
        } else {
            setData(emptyUser);
        }
    }

    function fetch() {
        fetchUsers((response) => {
            setUsers(response.data.users);
        });
    }

    function save() {
        saveUser(data, (response) => {
            fetch();
            setShowDialog(false);
        });
    }

    function deleteUsr() {
        deleteUser(data.id, (response) => {
            fetch();
        });
    }

    function requestPass() {
        requestPassword(data.id, (response) => {
            setSnackbar(true);
        });
    }

    return (
        <AdminUserContext.Provider
            value={{
                data,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                snackbar,
                setSnackbar,
                users,
                setUsers,
                selectUser,
                requestPass,
                errors,
                hasError,
                handleChange,
                fetch,
                save,
                deleteUsr,
                showTableEditButtons,
                downloadSignature,
            }}
        >
            {children}
        </AdminUserContext.Provider>
    );
};

export default AdminUserContext;
