import { useLocation, useParams } from "react-router-dom";
import { ReportListProvider as ReportListProvider } from "./ReportListContext";
import ReportListPageContent from "./ReportListPageContent";

function ReportListPage() {
    const location = useLocation();
    let isReturn = location.pathname.endsWith("return");

    return (
        <ReportListProvider isReturn={isReturn}>
            <ReportListPageContent isReturn={isReturn} />
        </ReportListProvider>
    );
}

export default ReportListPage;
