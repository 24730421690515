import { createContext, useState, useLayoutEffect, useEffect } from "react";
import fetchCounties from "../../../api/countyApi";
import { useCountyAuthorityApi } from "../../../api/countyAuthorityApi";
import { validateEmail } from "../../../utils/validators";

const emptyCountyAuthority = {
    id: null,
    name: "",
    address: "",
    county: null,
    administrator: "",
    emailAddresses: "",
};

const emptyErrors = {
    emailAddresses: null,
};

const CountyAuthorityContext = createContext({});

export const CountyAuthorityProvider = ({ children }) => {
    const { getAuthorities, saveAuthority, deleteAuthority } =
        useCountyAuthorityApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [counties, setCounties] = useState([]);
    const [authorities, setAuthorities] = useState([]);
    const [errors, setErrors] = useState(emptyErrors);
    const [data, setData] = useState(emptyCountyAuthority);

    console.log(errors.emailAddresses);

    const hasError =
        !data.name ||
        !data.address ||
        !data.county ||
        !data.administrator ||
        !data.emailAddresses ||
        errors.emailAddresses;

    const showTableEditButtons = data.id === null ? false : true;

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchCountyAuthorities();
        fetchCounties().then((result) => {
            setCounties(result);
        });
    }, []);

    useEffect(() => {
        console.log(data.emailAddresses);
        const emailAddresses = data.emailAddresses
            ?.split(",")
            .map((email) => email.trim());
        const allEmailValid = emailAddresses
            .filter((email) => email)
            .every((email) => validateEmail(email));
        let errorMessage;
        if (allEmailValid) {
            errorMessage = null;
        } else {
            errorMessage = "Hibás email címet tartalmaz";
        }
        setErrors((prevData) => ({
            ...prevData,
            emailAddresses: errorMessage,
        }));
    }, [data.emailAddresses]);

    function setSelectedAuthority(id) {
        if (id != null) {
            const authority = authorities.find(
                (authority) => authority.id === id
            );
            setData(authority);
        } else {
            setData(emptyCountyAuthority);
        }
    }

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    function fetchCountyAuthorities() {
        getAuthorities((response) => {
            setAuthorities(response.data.countyauthorities);
        });
    }

    function save() {
        saveAuthority(data, (response) => {
            fetchCountyAuthorities();
            setShowDialog(false);
        });
    }

    function deleteCountyAuthority() {
        deleteAuthority(data.id, (response) => {
            fetchCountyAuthorities();
        });
    }

    return (
        <CountyAuthorityContext.Provider
            value={{
                data,
                authorities,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                counties,
                errors,
                hasError,
                handleChange,
                save,
                deleteCountyAuthority,
                showTableEditButtons,
                setSelectedAuthority,
            }}
        >
            {children}
        </CountyAuthorityContext.Provider>
    );
};

export default CountyAuthorityContext;
