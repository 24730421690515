import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { useTemplateApi } from "../../../api/templateApi";

const emptyTemplates = {
    id: null,
    type: "",
    description: "",
    template: null,
};

const emptyErrors = {
    type: null,
    description: null,
    template: null,
};

const AdminTemplateContext = createContext({});

export const AdminTemplateProvider = ({ children }) => {
    const { getTemplates, saveTemplate, deleteTemplate, downloadTemplate } =
        useTemplateApi();
    const [showDialog, setShowDialog] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [errors, setErrors] = useState(emptyErrors);
    const [data, setData] = useState({ emptyTemplates });

    const hasError = !data.type;
    const showTableEditButtons = data.id != null;

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    function selectTemplate(id) {
        const template = templates.find((template) => template.id === id);
        setData(template);
    }

    function fetchTemplates() {
        getTemplates((response) => {
            setTemplates(response.data.templates);
        });
    }

    function download() {
        downloadTemplate(data.id);
    }

    function save() {
        saveTemplate(data, () => {
            fetchTemplates();
            setShowDialog(false);
        });
    }

    function deleteTempl() {
        deleteTemplate(data.id, (response) => {
            fetchTemplates();
        });
    }

    return (
        <AdminTemplateContext.Provider
            value={{
                data,
                showDialog,
                setShowDialog,
                templates,
                setTemplates,
                selectTemplate,
                errors,
                hasError,
                handleChange,
                download,
                fetchTemplates,
                save,
                deleteTempl,
                showTableEditButtons,
            }}
        >
            {children}
        </AdminTemplateContext.Provider>
    );
};

export default AdminTemplateContext;
