import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getRoleById } from "../../../utils/Roles";
import useAdminUserContext from "./useAdminUserContext";

function AdminUserTable() {
    const { users, selectUser, downloadSignature } = useAdminUserContext();

    const columns = [
        { field: "email", headerName: "Email", minWidth: 70, flex: 0.2 },
        {
            field: "lastName",
            headerName: "Vezetéknév",
            minWidth: 130,
            flex: 0.2,
        },
        {
            field: "firstName",
            headerName: "Keresztnév",
            minWidth: 130,
            flex: 0.1,
        },
        {
            field: "role",
            headerName: "Szerepkör",
            minWidth: 130,
            flex: 0.2,
            valueGetter: (params) => getRoleById(params.row.role),
        },
        {
            field: "active",
            headerName: "Aktív",
            type: "boolean",
            minWidth: 90,
            flex: 0.1,
        },
        {
            field: "hasPassword",
            headerName: "Van jelszó",
            type: "boolean",
            minWidth: 90,
            flex: 0.1,
        },
        {
            field: "signature",
            headerName: "Aláírás",
            minWidth: 70,
            flex: 0.1,
            renderCell: (params) =>
                params.row.hasSignature ? (
                    <strong>
                        <Button
                            variant="text"
                            onClick={() => downloadSignature(params.row.id)}
                        >
                            Letöltés
                        </Button>
                    </strong>
                ) : (
                    ""
                ),
        },
    ];

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        rowSelected(id);
    }

    function rowSelected(id) {
        selectUser(id);
    }

    return (
        <div style={{ height: 400, width: "100%" }}>
            <DataGrid
                rows={users}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default AdminUserTable;
