import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useStandardIssueContext from "./useStandardIssueContext";

const columns = [
    {
        field: "standardSection",
        headerName: "Szabványpont",
        minWidth: 70,
        flex: 0.2,
    },
    { field: "description", headerName: "Magyarázat", minWidth: 70, flex: 0.6 },
    {
        field: "dangerous",
        headerName: "Balesetveszélyes",
        minWidth: 70,
        flex: 0.2,
        type: "boolean",
    },
];

function StandardIssueTable() {
    const { standardIssues, setSelectedStandardIssue } =
        useStandardIssueContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedStandardIssue(id);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={standardIssues}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default StandardIssueTable;
