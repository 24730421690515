import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import { IMAGE_URL } from "./Url";
import { axiosPrivate } from "./axios";

export function useImageApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();

    async function deleteImageFromRemote(imageName, reportId, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${IMAGE_URL}/${imageName}/report/${reportId}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return {
        deleteImageFromRemote,
    };
}
