export const PASSWORD_RESET_INITIAL_STATE = {
    email: "",
    code: "",
    password: "",
    passwordAgain: "",
    passwordError: "",
    passwordAgainError: "",
    isValidPassword: false,
};

export const PASSWORD_RESET_ACTION_TYPES = {
    SET_INPUT_VALUE: "SET_INPUT_VALUE",
    CLEAR_INPUT_VALUES: "CLEAR_INPUT_VALUES",
    PASSWORD_FOCUS_LOST: "PASSWORD_FOCUS_LOST",
};

export const passwordReserReducer = (state, action) => {
    let password;
    if (action.payload.name === "password") {
        password = action.payload.value;
    } else {
        password = state.password;
    }
    let passwordAgain;
    if (action.payload.name === "passwordAgain") {
        passwordAgain = action.payload.value;
    } else {
        passwordAgain = state.password;
    }
    switch (action.type) {
        case PASSWORD_RESET_ACTION_TYPES.SET_INPUT_VALUE:
            let passwordAgainError;
            if (
                action.payload.name === "passwordAgain" &&
                action.payload.value !== state.password
            ) {
                passwordAgainError = "Jelszó és megerősítés nem egyezik";
            } else {
                passwordAgainError = "";
            }
            const isValidPassword =
                password.trim() !== "" &&
                passwordAgain.trim() !== "" &&
                password.trim() === passwordAgain.trim();
            return {
                ...state,
                passwordError: "",
                passwordAgainError: passwordAgainError,
                isValidPassword: isValidPassword,
                [action.payload.name]: action.payload.value,
            };
        case PASSWORD_RESET_ACTION_TYPES.CLEAR_INPUT_VALUES:
            return {
                ...state,
                passwordError: "",
                passwordAgainError: "",
                email: "",
                password: "",
            };
        case PASSWORD_RESET_ACTION_TYPES.PASSWORD_FOCUS_LOST:
            password = action.payload.value;
            if (password.trim() === "") {
                return {
                    ...state,
                    passwordError: "Kötelező mező",
                };
            } else {
                return {
                    ...state,
                };
            }
        default:
            break;
    }
};
