import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import useGeneralErrorContext from "./useGeneralErrorContext";

/* 
    severity:
    error
    warning
    info
    success 
*/

export default function GeneralErrorSnackBar() {
    const { showGeneralError, setShowGeneralError, message } =
        useGeneralErrorContext();

    const handleClose = () => {
        setShowGeneralError(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={4000}
                open={showGeneralError}
                onClose={handleClose}
                key={message}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
