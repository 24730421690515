import { createContext, useState } from "react";

const FullScreenProgressBarContext = createContext({});

export const FullScreenProgressBarProvider = ({ children }) => {
    const [showFullScreenProgressBar, setShowFullScreenProgressBar] =
        useState(false);

    return (
        <FullScreenProgressBarContext.Provider
            value={{
                showFullScreenProgressBar,
                setShowFullScreenProgressBar,
            }}
        >
            {children}
        </FullScreenProgressBarContext.Provider>
    );
};

export default FullScreenProgressBarContext;
