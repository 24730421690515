import TextField from "@mui/material/TextField";
import useLocationContext from "./useLocationContext";
import { Autocomplete } from "@mui/material";
import { Box } from "@mui/system";

function LocationEditDialogContent() {
    const { cities, operators, data, handleChange, errors } =
        useLocationContext();

    const uniqueCities = [...new Set(cities.map((city) => city.city))];

    return (
        <Box>
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic name"
                label="Név"
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.name}
                error={errors.name != null}
                helperText={errors.name}
                name="name"
                required
            />
            <Autocomplete
                sx={{ width: "100%", my: 1 }}
                id="playground-location-zip"
                freeSolo
                name="zip"
                value={data.zip}
                onInputChange={(event, newInputValue) => {
                    if (event != null) {
                        event.target.name = "zip";
                        event.target.value = newInputValue;
                        handleChange(event);
                    }
                }}
                required
                options={cities.map((city) => city.zip)}
                renderInput={(params) => (
                    <TextField {...params} label="Irányítószám" />
                )}
            />
            <Autocomplete
                sx={{ width: "100%", my: 1 }}
                id="playground-location-city"
                freeSolo
                name="city"
                value={data.city}
                required
                onInputChange={(event, newInputValue) => {
                    if (event != null) {
                        event.target.name = "city";
                        handleChange(event);
                    }
                }}
                options={uniqueCities}
                renderInput={(params) => (
                    <TextField {...params} label="Város" />
                )}
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="playground-location-address"
                label="Cím"
                multiline
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.address}
                error={errors.address != null}
                helperText={errors.address}
                name="address"
                required
            />
            <Autocomplete
                sx={{ width: "100%", my: 1 }}
                id="free-solo-demo"
                name="operator"
                value={data.operator}
                onChange={(event, newValue) => {
                    if (event != null) {
                        event.target.type = "autocomplete";
                        event.target.name = "operator";
                        event.target.selectedOption = newValue;
                        handleChange(event);
                    }
                }}
                options={operators.map((operator) => operator)}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                    <TextField {...params} label="Üzemeltető * " />
                )}
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic email"
                label="Kapcsolattartó"
                multiline
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.contactPerson}
                error={errors.contactPerson != null}
                helperText={errors.contactPerson}
                name="contactPerson"
            />
        </Box>
    );
}

export default LocationEditDialogContent;
