import { createContext, useState, useLayoutEffect, useEffect } from "react";
import fetchCities from "../../../api/citiesApi";
import { useManufacturerApi } from "../../../api/manufacturerApi";

const emptyManufacturer = {
    id: null,
    name: null,
    city: null,
    zip: null,
    address: null,
    priority: false,
    csv: null,
};

const emptyErrors = {
    name: null,
    csv: null,
};

const ManufacturerContext = createContext({});

export const ManufacturerProvider = ({ children }) => {
    const { getManufacturers, deleteManufacturer, uploadManufacturerCsv } =
        useManufacturerApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showNewManufacturerDialog, setShowNewManufacturerDialog] =
        useState(false);

    const [manufacturers, setManufacturers] = useState([]);
    const [errors, setErrors] = useState(emptyErrors);
    const [allCities, setAllCities] = useState([]);
    const [cities, setCities] = useState([]);
    const [data, setData] = useState({ emptyManufacturer });
    const hasError = !data.name || errors.name;

    const showTableEditButtons = data.id === null ? false : true;
    function validate(name, value) {
        switch (name) {
            case "name": {
                break;
            }
            case "city": {
                break;
            }
            case "zip": {
                if (value.toString().length > 0) {
                    const c = allCities.filter((city) =>
                        city.zip.startsWith(value)
                    );
                    if (c.length === 1) {
                        setData((prevData) => ({
                            ...prevData,
                            city: c[0].city,
                        }));
                    }
                    setCities(c);
                } else {
                    setCities(allCities);
                }
                break;
            }
            case "address": {
                break;
            }
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        validate(name, value);

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchManufacturers();
        fetchCities().then((result) => {
            setAllCities(result);
        });
    }, []);

    function setSelectedManufacturer(id) {
        if (id != null) {
            const manufacturer = manufacturers.find(
                (manufacturer) => manufacturer.id === id
            );
            setData(manufacturer);
        } else {
            setCities([]);
            setData(emptyManufacturer);
        }
    }

    useLayoutEffect(() => {
        if (!showNewManufacturerDialog) {
            setErrors(emptyErrors);
        }
    }, [showNewManufacturerDialog]);

    function fetchManufacturers() {
        getManufacturers((response) => {
            setManufacturers(response.data.manufacturers);
            setData(emptyManufacturer);
        });
    }

    function uploadCsv() {
        uploadManufacturerCsv(data.csv, (response) => {
            fetchManufacturers();
        });
    }

    function deleteManufact() {
        deleteManufacturer(data.id, (response) => {
            fetchManufacturers();
        });
    }

    return (
        <ManufacturerContext.Provider
            value={{
                cities,
                data,
                setData,
                showNewManufacturerDialog,
                setShowNewManufacturerDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                fetchManufacturers,
                manufacturers,
                setManufacturers,
                errors,
                hasError,
                handleChange,
                deleteManufact,
                uploadCsv,
                showTableEditButtons,
                setSelectedManufacturer,
            }}
        >
            {children}
        </ManufacturerContext.Provider>
    );
};

export default ManufacturerContext;
