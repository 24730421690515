import { AdminUserProvider } from "./AdminUserContext";
import AdminUserPageContent from "./AdminUserPageContent";

function AdminUserPage() {
    return (
        <AdminUserProvider>
            <AdminUserPageContent />
        </AdminUserProvider>
    );
}

export default AdminUserPage;
