import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { LOCATION_URL, OPERATOR_URL, REPORT_URL } from "../../../api/Url";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import fetchCities from "../../../api/citiesApi";
import useFullScreenProgressBarContext from "../../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../../common/generalerrorsnackbar/useGeneralErrorContext";
import useReportContext from "../useReportContext";
import { emptyLocation } from "../ReportContext";
import { useLocationApi } from "../../../api/locationApi";
import { useOperatorApi } from "../../../api/operatorApi";
import useOperatorContext from "../../basicdata/operator/useOperatorContext";

const emptyErrors = {
    name: null,
    city: null,
    zip: null,
    address: null,
    operator: null,
    contactPerson: null,
};

const ReportLocationContext = createContext({});

export const ReportLocationProvider = ({ children }) => {
    const { getLocations } = useLocationApi();
    const { report, updateReport, setNextEnabled } = useReportContext();
    const { operators, fetchOperators } = useOperatorContext();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showTableLoading, setShowTableLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [allLocations, setAllLocations] = useState([]);
    const [locations, setLocations] = useState([]);

    const [errors, setErrors] = useState(emptyErrors);

    const [allCities, setAllCities] = useState([]);
    const [cities, setCities] = useState(allCities);

    const [data, setData] = useState(report.location ?? emptyLocation);

    const hasError =
        !data.name ||
        !data.city ||
        !data.zip ||
        !data.address ||
        !data.operator ||
        errors.name ||
        errors.city ||
        errors.zip ||
        errors.address;
    setNextEnabled(!hasError);

    function onNameChange(event) {
        let value = event.target.textContent;
        if (value.trim() === "") {
            value = event.target.value;
        }
        if (value.trim() === "" && data.id !== null) {
            setData(emptyLocation);
            return;
        }
        const values = value.split(" | ");
        const name = values[0];
        const city = values[1].split(", ")[0];
        const loc = allLocations.filter(
            (location) =>
                location.name.toLowerCase().includes(name.toLowerCase()) &&
                location.city.toLowerCase().includes(city.toLowerCase())
        );
        if (loc.length === 1) {
            setData(loc[0]);
        }
    }

    function validate(name, value) {
        switch (name) {
            case "name": {
                const loc = allLocations.filter(
                    (location) =>
                        location.name
                            .toLowerCase()
                            .includes(value.toLowerCase()) ||
                        location.city
                            .toLowerCase()
                            .includes(value.toLowerCase())
                );
                if (loc.length === 0) {
                    setLocations(allLocations);
                } else {
                    setLocations(loc);
                }
                break;
            }
            case "zip": {
                if (value.toString().length > 0) {
                    const city = allCities.filter((city) =>
                        city.zip.startsWith(value)
                    );
                    if (city.length === 1) {
                        setData((prevData) => ({
                            ...prevData,
                            city: city[0].city,
                        }));
                    }
                    setCities(city);
                    break;
                }
                break;
            }
            case "operator": {
                break;
            }
            default:
                break;
        }
        return value;
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            case "autocomplete": {
                if (e.target.selectedOption) {
                    value = e.target.selectedOption;
                } else {
                    value = e.target.textContent;
                }
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        value = validate(name, value);

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const setOperator = (operator) => {
        setData((prevData) => ({
            ...prevData,
            operator: operator,
        }));
    };

    useEffect(() => {
        fetchLocations();
        fetchCities().then((result) => {
            setAllCities(result);
        });
    }, []);

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    function fetchLocations() {
        getLocations((response) => {
            setAllLocations(response.data.locations);
            setLocations(response.data.locations);
        });
    }

    const saveLocation = async () => {
        let response;
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                id: data.id,
                name: data.name,
                city: data.city,
                zip: data.zip,
                address: data.address,
                operator: data.operator.id,
                contactPerson: data.contactPerson,
            };
            if (request.id == null) {
                response = await axiosPrivate.post(
                    `${LOCATION_URL}`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
            } else {
                response = await axiosPrivate.put(
                    `${LOCATION_URL}/${request.id}`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
            }
            setData(emptyLocation);
            fetchLocations();
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return response.data.location;
    };

    const saveReportLocation = async (location) => {
        let success;
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                location: location.id,
            };
            const response = await axiosPrivate.put(
                `${REPORT_URL}/${report.baseData.id}/location`,
                JSON.stringify(request),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );
            updateReport("location", response.data.report.location);
            success = true;
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return success;
    };

    return (
        <ReportLocationContext.Provider
            value={{
                cities,
                data,
                operators,
                emptyLocation,
                saveReportLocation,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                locations,
                setLocations,
                errors,
                hasError,
                handleChange,
                onNameChange,
                saveLocation,
                showTableLoading,
                setOperator,
                fetchOperators,
            }}
        >
            {children}
        </ReportLocationContext.Provider>
    );
};

export default ReportLocationContext;
