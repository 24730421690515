import { Box, Grid, Paper, styled } from "@mui/material";
import BaseDataCard from "./BaseDataCard";
import SportEquipmentReporCard from "./SportEquipmentReportCard";
import PlaygroundReportCard from "./PlaygroundReportCard";
import StatisticsCard from "./StatisticsCard";
import { SplitButton } from "../common/SplitButton";

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#171717" : "#eee",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const Dashboard = () => {
    return (
        <Box sx={{ mt: 6 }}>
            <Grid container spacing={3} rowSpacing={5} alignItems="stretch">
                <Grid item xs={6}>
                    <Item>
                        <PlaygroundReportCard />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <SportEquipmentReporCard />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <BaseDataCard />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <StatisticsCard />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
