import { createContext, useState, useLayoutEffect, useEffect } from "react";
import fetchCities from "../../../api/citiesApi";
import { useOperatorApi } from "../../../api/operatorApi";

const emptyOperator = {
    id: null,
    name: null,
    city: null,
    zip: null,
    address: null,
    companyRegistrationNumber: null,
    contactName: null,
    contactEmail: null,
    contactPhoneNumber: null,
    mailContactName: null,
    csv: null,
};

const emptyErrors = {
    name: null,
    csv: null,
};

const OperatorContext = createContext({});

export const OperatorProvider = ({ children }) => {
    const { getOperators, uploadOperatorCsv, deleteOperator } =
        useOperatorApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState(emptyErrors);
    const [allCities, setAllCities] = useState([]);
    const [cities, setCities] = useState([]);
    const [operators, setOperators] = useState({ emptyOperator });
    const hasError =
        !data.name ||
        !data.city ||
        !data.zip ||
        !data.address ||
        !data.companyRegistrationNumber ||
        errors.name;
    const showTableEditButtons = data.id !== null;

    function validate(name, value) {
        switch (name) {
            case "name": {
                break;
            }
            case "city": {
                break;
            }
            case "zip": {
                if (value.toString().length > 0) {
                    const c = allCities.filter((city) =>
                        city.zip.startsWith(value)
                    );
                    if (c.length === 1) {
                        setData((prev) => ({
                            ...prev,
                            city: c[0].city,
                        }));
                    }
                    setCities(c);
                }
                break;
            }
            case "address": {
                break;
            }
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        validate(name, value);

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchOperators();
        fetchCities().then((result) => {
            setAllCities(result);
        });
    }, []);

    function setSelectedOperator(id) {
        if (id != null) {
            const operator = operators.find((operator) => operator.id === id);
            setData(operator);
        } else {
            setCities([]);
            setData(emptyOperator);
        }
    }

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    function fetchOperators() {
        getOperators((response) => {
            setData(emptyOperator);
            setOperators(response.data.operators);
        });
    }

    function uploadCsv() {
        uploadOperatorCsv(data.csv, (response) => {
            fetchOperators();
        });
    }

    function deleteOperat() {
        deleteOperator(data.id, (response) => {
            fetchOperators();
        });
    }

    return (
        <OperatorContext.Provider
            value={{
                cities,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                operators,
                data,
                setData,
                setSelectedOperator,
                errors,
                hasError,
                handleChange,
                deleteOperat,
                uploadCsv,
                showTableEditButtons,
                fetchOperators,
            }}
        >
            {children}
        </OperatorContext.Provider>
    );
};

export default OperatorContext;
