import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { ReportType } from "../../utils/reportType";
import PageTitleWithBackButton from "../common/PageTitleWithBackButton";
import ReportBaseDataPage from "./basedata/ReportBaseDataPage";
import ReportLocationPage from "./location/ReportLocationPage";
import useReportContext from "./useReportContext";
import { useParams } from "react-router-dom";
import ReportSelectCoverImage from "./coverimage/selectCoverImage/ReportSelectCoverImage";
import PlaygroundReportEquipmentPage from "./equipment/PlaygroundReportEquipmentPage";
import PlaygroundReportGeneratePage from "./generate/PlaygroundReportGeneratePage";
import PlaygroundReportEquipmentsPage from "./equipments/PlaygroundReportEquipmentsPage";

const steps = [
    "Alapadatok",
    "Képfeltöltés",
    "Helyszín",
    "Eszközök",
    "Adatfeltöltés",
    "Dokumentum generálás",
];

const PlaygroundReportContent = () => {
    let params = useParams();
    const isReturn = params.option === "return";
    const { report, activeStep } = useReportContext();

    function renderContent(param) {
        switch (param) {
            case 0: {
                return <ReportBaseDataPage />;
            }
            case 1: {
                return <ReportSelectCoverImage />;
            }
            case 2: {
                return <ReportLocationPage />;
            }
            case 3: {
                return <PlaygroundReportEquipmentsPage />;
            }
            case 4: {
                return <PlaygroundReportEquipmentPage />;
            }
            case 5: {
                return <PlaygroundReportGeneratePage />;
            }
            default:
                return <Box></Box>;
        }
    }

    let title = "";
    let reportNumberWithoutPrefix;
    let reportNumber = "";
    if (report.baseData.number != null && report.baseData.year != null)
        reportNumberWithoutPrefix = `${report.baseData.number}/${report.baseData.year}`;
    if (report.baseData.reportType === ReportType.PLAYGROUND) {
        if (reportNumberWithoutPrefix)
            reportNumber = `J${reportNumberWithoutPrefix}`;
        title = `Játszótéri eszköz ${reportNumber}`;
    } else if (
        report.baseData.reportType.code === ReportType.SPORT_EQUIPMENT.code
    ) {
        if (reportNumberWithoutPrefix)
            reportNumber = `F${reportNumberWithoutPrefix}`;
        title = `Sporteszköz ${reportNumber}`;
    }

    return (
        <Box sx={{ mt: 2 }}>
            <PageTitleWithBackButton title={title} />
            <Stepper activeStep={activeStep} sx={{ my: 2 }}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Box sx={{ flexGrow: 1, my: 8 }}>{renderContent(activeStep)}</Box>
        </Box>
    );
};

export default PlaygroundReportContent;
