import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
    Button,
    Container,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useReportApi } from "../../../../api/reportApi";
import AlertDialog from "../../../common/AlertDialog";
import {
    getCurrentYear,
    getYearsFromNowTo2023,
} from "../../../../utils/DateUtils";
import { getReportNumber } from "../../../../utils/ReportUtil";

const ReportListDialog = ({ reportType, onClose, onReportSelected }) => {
    const apiRef = useGridApiRef();
    const [reports, setReports] = useState([]);
    const [selectedYear, setSelectedYear] = useState(getCurrentYear());
    const [selectedReport, setSelectedReport] = useState(null);
    const [showCopyConfirm, setShowCopyConfirm] = useState(false);
    const { getReportsByReportTypeAndYear } = useReportApi();

    useEffect(() => {
        fetchReports(reportType.urlparam, selectedYear);
    }, [selectedYear]);

    function fetchReports(reportType, year) {
        getReportsByReportTypeAndYear(reportType, year, (response) => {
            setReports(response.data.reports);
        });
    }

    const columns = [
        {
            field: "number",
            headerName: "Sorszám",
            minWidth: 70,
            flex: 0.1,
            valueGetter: (params) => getReportNumber(params.row),
        },
        {
            field: "date",
            headerName: "Ellenőrzés dátuma",
            minWidth: 70,
            flex: 0.1,
            valueGetter: (params) =>
                dayjs(params.row.date).format("YYYY.MM.DD."),
        },
        {
            field: "shortName",
            headerName: "Rövid megnevezés",
            minWidth: 70,
            flex: 0.3,
        },
        {
            field: "location",
            headerName: "Helyszín",
            minWidth: 70,
            flex: 0.3,
            valueGetter: (params) => params.row.location?.name,
        },
    ];

    const years = getYearsFromNowTo2023();

    return (
        <Container>
            <FormControl sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Év</InputLabel>
                <Select
                    id="year-selection"
                    value={selectedYear}
                    label="Év"
                    onChange={(event) => setSelectedYear(event.target.value)}
                >
                    {years.map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <DataGrid
                sx={{ mt: 2, height: 500 }}
                density="compact"
                apiRef={apiRef}
                rows={reports}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[Math.min(100, reports.length)]}
                onRowClick={(params) => {
                    console.log(params);
                    setSelectedReport(params.row.id);
                }}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
            <DialogActions>
                <Button name="cancel" onClick={onClose}>
                    Mégse
                </Button>
                <Button
                    name="save"
                    disabled={!selectedReport}
                    onClick={() => {
                        setShowCopyConfirm(true);
                    }}
                >
                    Másolás
                </Button>
            </DialogActions>
            <AlertDialog
                open={[showCopyConfirm, setShowCopyConfirm]}
                message="Biztos átmásolod az összes eszközt?"
                positiveButton="Másolás"
                negativeButton="Mégse"
                buttonHandler={(result) => {
                    if (result === true) {
                        onReportSelected(selectedReport);
                    } else {
                        setShowCopyConfirm(false);
                    }
                }}
            />
        </Container>
    );
};

export default ReportListDialog;
