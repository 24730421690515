import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useEquipmentCategoryContext from "./useEquipmentCategoryContext";

function EquipmentCategoryEditDialog() {
    const {
        data,
        handleChange,
        showDialog,
        setShowDialog,
        save,
        errors,
        hasError,
    } = useEquipmentCategoryContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title =
        data.id === null
            ? "Új eszköz kategória"
            : "Eszköz kategória szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Név"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.name}
                    error={errors.name != null}
                    helperText={errors.name}
                    name="name"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Megjegyzés"
                    multiline
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.description}
                    error={errors.description != null}
                    helperText={errors.description}
                    name="description"
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EquipmentCategoryEditDialog;
