import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useManufacturerContext from "./useManufacturerContext";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import { useManufacturerApi } from "../../../api/manufacturerApi";

function ManufacturerEditDialog({ onClose }) {
    const {
        data,
        setData,
        cities,
        handleChange,
        showNewManufacturerDialog,
        setShowNewManufacturerDialog,
        errors,
        hasError,
    } = useManufacturerContext();
    const { saveManufacturer } = useManufacturerApi();

    const uniqueCities = [...new Set(cities.map((city) => city.city))];

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                saveManufacturer(data, (response) => {
                    onClose(response);
                });
                break;
            }
            default:
                break;
        }
        setShowNewManufacturerDialog(false);
    };

    const title = data.id === null ? "Új gyártó" : "Gyártó szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showNewManufacturerDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Név"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.name}
                    error={errors.name != null}
                    helperText={errors.name}
                    name="name"
                    required
                />
                <Autocomplete
                    sx={{ width: "100%", my: 1 }}
                    id="free-solo-demo"
                    freeSolo
                    name="zip"
                    value={data.zip}
                    onInputChange={(event, newInputValue) => {
                        if (event != null) {
                            event.target.name = "zip";
                            event.target.value = newInputValue;
                            handleChange(event);
                        }
                    }}
                    options={cities.map((city) => city.zip)}
                    renderInput={(params) => (
                        <TextField {...params} label="Irányítószám" />
                    )}
                />
                <Autocomplete
                    sx={{ width: "100%", my: 1 }}
                    id="free-solo-demo"
                    freeSolo
                    name="city"
                    value={data.city}
                    onInputChange={(event, newInputValue) => {
                        if (event != null) {
                            event.target.name = "city";
                            handleChange(event);
                        }
                    }}
                    options={uniqueCities}
                    renderInput={(params) => (
                        <TextField {...params} label="Város" />
                    )}
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Cím"
                    multiline
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.address}
                    error={errors.address != null}
                    helperText={errors.address}
                    name="address"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            name="priority"
                            onChange={handleChange}
                            checked={data.priority}
                        />
                    }
                    label="Kiemelt"
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ManufacturerEditDialog;
