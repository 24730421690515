import { StandardIssueProvider } from "./StandardIssueContext";
import StandardIssuePageContent from "./StandardIssuePageContent";

function StandardIssuePage() {
    return (
        <StandardIssueProvider>
            <StandardIssuePageContent />
        </StandardIssueProvider>
    );
}

export default StandardIssuePage;
