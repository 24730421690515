import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import useStandardContext from "./useStandardContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function StandardTable() {
    const { standards, setSelectedStandard, download } = useStandardContext();

    const columns = [
        {
            field: "code",
            headerName: "Szabvány",
            minWidth: 70,
            flex: 0.25,
        },
        { field: "name", headerName: "Megnevezés", minWidth: 130, flex: 0.3 },
        { field: "section", headerName: "Fejezet", minWidth: 130, flex: 0.3 },
        {
            field: "type",
            headerName: "Eszköz típus",
            minWidth: 130,
            flex: 0.2,
            valueGetter: (params) =>
                params.row.types.map((type) => `${type.name} `),
        },
        {
            field: "actions",
            type: "actions",
            headerName: "PDF",
            width: 90,
            cellClassName: "actions",
            getActions: (params) => {
                return params.row.hasDocument
                    ? [
                          <GridActionsCellItem
                              icon={<PictureAsPdfIcon />}
                              label="Megtekintés"
                              onClick={() => download(params.row.id)}
                              color="inherit"
                          />,
                      ]
                    : [];
            },
        },
    ];

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedStandard(id);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={standards}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default StandardTable;
