import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useEquipmentTypeContext from "./useEquipmentTypeContext";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.2 },
    {
        field: "description",
        headerName: "Megjegyzés",
        minWidth: 130,
        flex: 0.6,
    },
    {
        field: "categoryName",
        headerName: "Eszköz kategória",
        minWidth: 130,
        flex: 0.2,
        valueGetter: (params) => params.row.category?.name,
    },
];

function EquipmentTypeTable() {
    const { equipmentTypes, setSelectedEquipmentType } =
        useEquipmentTypeContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedEquipmentType(id);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={equipmentTypes}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default EquipmentTypeTable;
