import { EquipmentGroupProvider } from "./EquipmentGroupContext";
import EquipmentGroupPageContent from "./EquipmentGroupPageContent";

function EquipmentGroupPage() {
    return (
        <EquipmentGroupProvider>
            <EquipmentGroupPageContent />
        </EquipmentGroupProvider>
    );
}

export default EquipmentGroupPage;
