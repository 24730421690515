import { Button, Card, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useReducer, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../api/axios";
import md5 from "md5";

import {
    passwordReserReducer,
    PASSWORD_RESET_ACTION_TYPES,
    PASSWORD_RESET_INITIAL_STATE,
} from "./passwordResetReducer";
import { USER_URL } from "../../api/Url";

const PASSWORD_RESET_URL = `${USER_URL}/passwordreset`;

const PasswordReset = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, dispatch] = useReducer(
        passwordReserReducer,
        PASSWORD_RESET_INITIAL_STATE
    );
    const [queryParamsProcessed, setQueryParamsProcessed] = useState();
    const [errMsg, setErrMsg] = useState("");
    const navigate = useNavigate();
    const errRef = useRef();

    useEffect(() => {
        setQueryParamsProcessed(true);
        if (queryParamsProcessed) return;
        const email = searchParams.get("email");
        const code = searchParams.get("code");
        if (!email || !code) {
            navigate("/", { replace: true });
        }
        dispatch({
            type: PASSWORD_RESET_ACTION_TYPES.SET_INPUT_VALUE,
            payload: { name: "email", value: email },
        });
        dispatch({
            type: PASSWORD_RESET_ACTION_TYPES.SET_INPUT_VALUE,
            payload: { name: "code", value: code },
        });
    }, []);

    const setInputValue = (e) => {
        dispatch({
            type: PASSWORD_RESET_ACTION_TYPES.SET_INPUT_VALUE,
            payload: { name: e.target.name, value: e.target.value },
        });
    };

    const onFocusLost = (e) => {
        dispatch({
            type: PASSWORD_RESET_ACTION_TYPES.PASSWORD_FOCUS_LOST,
            payload: { name: e.target.name, value: e.target.value },
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const md5Password = md5(state.password);
        try {
            const response = await axios.post(
                PASSWORD_RESET_URL,
                JSON.stringify({
                    email: state.email,
                    code: state.code,
                    password: md5Password,
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );
            dispatch({ type: PASSWORD_RESET_ACTION_TYPES.CLEAR_INPUT_VALUES });
            navigate("/login", { replace: true });
        } catch (error) {
            if (!error.response) {
                setErrMsg("No Server Response");
            } else if (error.response?.status === 400) {
                setErrMsg("Hiányzó email cím vagy jelszó");
            } else if (error.response?.status === 401) {
                setErrMsg("Unauthorized");
            } else {
                setErrMsg("Unexpected error");
            }
        }
    };

    return (
        <section>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Card component="span" sx={{ minWidth: 275, p: 2 }}>
                    <p
                        style={{ color: "red" }}
                        ref={errRef}
                        className={errMsg ? "errmsg" : "offscreen"}
                    >
                        {errMsg}
                    </p>
                    <h1>Jelszó létrehozás</h1>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            sx={{ width: "100%", my: 1 }}
                            id="outlined-basic email"
                            label="Jelszó"
                            variant="outlined"
                            autoFocus
                            type="password"
                            autoComplete="off"
                            onBlur={onFocusLost}
                            error={state.passwordError}
                            helperText={state.passwordError}
                            onChange={setInputValue}
                            value={state.password}
                            name="password"
                            required
                        />
                        <br />
                        <TextField
                            sx={{ width: "100%", my: 1 }}
                            id="outlined-basic password"
                            label="Jelszó megerősítés"
                            variant="outlined"
                            type="password"
                            onChange={setInputValue}
                            error={state.passwordAgainError}
                            helperText={state.passwordAgainError}
                            value={state.passwordAgain}
                            name="passwordAgain"
                            required
                        />
                        <br />
                        <Button
                            variant="contained"
                            disabled={!state.isValidPassword}
                            type="submit"
                        >
                            Jelszó létrehozás
                        </Button>
                    </form>
                </Card>
            </Box>
        </section>
    );
};

export default PasswordReset;
