import { USER_URL } from "../api/Url";
import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get(`${USER_URL}/refresh`, {
            withCredentials: true,
        });
        setAuth((prev) => {
            // console.log(JSON.stringify(`prev AT: ${prev}`));
            // console.log(JSON.stringify(`new AT: ${response.data.accessToken}`));
            return {
                ...prev,
                role: response.data.role,
                accessToken: response.data.accessToken,
            };
        });
        return response.data.accessToken;
    };
    return refresh;
};

export default useRefreshToken;
