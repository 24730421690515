import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { IMAGE_URL, REPORT_URL } from "./Url";
import dayjs from "dayjs";

export function useReportApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getReportConfig(reportType, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${REPORT_URL}/config/${reportType.urlparam}`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveCoverImage(reportId, coverImage, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                image: coverImage,
            };
            await axiosPrivate
                .put(
                    `${REPORT_URL}/${reportId}/coverimage`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getReportsByReportTypeAndYear(reportType, year, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(
                    `${REPORT_URL}/list/reporttype/${reportType}/year/${year}`,
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getMyReportsByReportTypeAndYear(
        reportType,
        year,
        onSuccess
    ) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(
                    `${REPORT_URL}/list/me/reporttype/${reportType}/year/${year}`,
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveReportLocation(reportId, location, onSuccess) {
        let success;
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                location: location.id,
            };
            await axiosPrivate
                .put(
                    `${REPORT_URL}/${reportId}/location`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
            success = true;
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return success;
    }

    async function getReportEquipments(reportId, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${REPORT_URL}/${reportId}/equipments`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveReportEquipments(reportId, reportEquipments, onSuccess) {
        let success = false;
        setShowFullScreenProgressBar(true);
        try {
            const equipments = reportEquipments.map((item) => {
                return {
                    reportEquipmentId: item.reportEquipmentId,
                    equipmentId: item.equipment.id,
                    inspectionType: item.inspectionType.id,
                    certificateNumber: item.equipment.certificateNumber,
                    certificateDate: item.equipment.certificateDate,
                    image: item.equipment.image,
                };
            });
            const request = { equipments: equipments };
            await axiosPrivate
                .put(
                    `${REPORT_URL}/${reportId}/equipments`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
            success = true;
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return success;
    }

    async function saveEquipmentImage(equipmentId, imageName, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                image: imageName,
            };
            const response = await axiosPrivate
                .put(
                    `${REPORT_URL}/reportequipment/${equipmentId}/image`,
                    JSON.stringify(request),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getReportEquipmentAttributes(editedEquipmentId, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(
                    `${REPORT_URL}/reportequipment/${editedEquipmentId}/attributes`,
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveReportEquipmentAttributes(
        editedEquipmentId,
        params,
        onSuccess
    ) {
        let success = false;
        setShowFullScreenProgressBar(true);
        try {
            const attributes = [];
            for (var key in params) {
                const item = params[key];
                attributes.push({
                    id: item.id,
                    attributeId: key,
                    value: item.value,
                });
            }
            const request = { attributes: attributes };
            await axiosPrivate
                .put(
                    `${REPORT_URL}/reportequipment/${editedEquipmentId}/attributes`,
                    JSON.stringify(request),
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
            success = true;
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return success;
    }

    async function getReportImages(reportId, noProgressbar, onSuccess) {
        !noProgressbar && setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${IMAGE_URL}/thumbnails/${reportId}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getReportEquipmentIssues(equipmentId, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${REPORT_URL}/reportequipment/${equipmentId}/issues`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteReportEquipmentIssue(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${REPORT_URL}/reportequipment/${id}`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function copyReportEquipmentsFromOtherReport(
        reportId,
        copyFromReportId,
        onSuccess
    ) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .post(
                    `${REPORT_URL}/${reportId}/equipments/copy/${copyFromReportId}`,
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function generateReport(
        reportId,
        templates,
        reportDate,
        download,
        sendEmail,
        email,
        emailBody,
        attachments,
        separatedByEquipments,
        onSuccess
    ) {
        setShowFullScreenProgressBar(true);
        try {
            const formData = new FormData();
            formData.append(
                "templateTypes",
                templates.map((template) => template.type)
            );
            formData.append("reportDate", dayjs(reportDate).valueOf());
            formData.append("separatedByEquipments", separatedByEquipments);
            formData.append("sendEmail", sendEmail);
            formData.append("recipients", email);
            formData.append("emailBody", emailBody);
            attachments.map((file) => formData.append(file.name, file));

            const response = await axiosPrivate
                .post(`${REPORT_URL}/generate/${reportId}`, formData, {
                    headers: { "Content-Type": "application/json" },
                    responseType: "blob",
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                    return response;
                });
            if (download && !separatedByEquipments) {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement("a");
                const fileName = response.headers["content-disposition"]
                    .split("filename=")[1]
                    .replaceAll('"', "");
                link.href = href;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return {
        getReportConfig,
        saveCoverImage,
        getReportsByReportTypeAndYear,
        getMyReportsByReportTypeAndYear,
        getReportEquipments,
        saveReportEquipments,
        saveReportLocation,
        getReportEquipmentAttributes,
        saveReportEquipmentAttributes,
        getReportImages,
        getReportEquipmentIssues,
        deleteReportEquipmentIssue,
        saveEquipmentImage,
        copyReportEquipmentsFromOtherReport,
        generateReport,
    };
}
