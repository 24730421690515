import { OperatorProvider } from "./OperatorContext";
import OperatorPageContent from "./OperatorPageContent";

function OperatorPage() {
    return (
        <OperatorProvider>
            <OperatorPageContent />
        </OperatorProvider>
    );
}

export default OperatorPage;
