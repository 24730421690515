export function getYearsFromNowTo2023() {
    const years = [];

    for (let year = getCurrentYear(); year >= 2022; year--) {
        years.push(year);
    }

    return years;
}

export function getCurrentYear() {
    return new Date().getFullYear();
}
