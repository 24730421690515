import { LocationProvider } from "./LocationContext";
import LocationPageContent from "./LocationPageContent";

function LocationPage() {
    return (
        <LocationProvider>
            <LocationPageContent />
        </LocationProvider>
    );
}

export default LocationPage;
