import PlaygroundReportEquipmentsList from "./PlaygroundReportEquipmentsList";
import usePlaygroundReportEquipmentsContext from "./usePlaygroundReportEquipmentsContext";
import { PlaygroundReportEquipmentsByManufacturerProvider } from "./equipmentsByManufacturer/PlaygroundReportEquipmentsByManufacturerContext";
import PlaygroundReportEquipmentsByManufacturer from "./equipmentsByManufacturer/PlaygroundReportEquipmentsByManufacturer";
import { ManufacturerProvider } from "../../basicdata/manufacturer/ManufacturerContext";
import { EquipmentProvider } from "../../basicdata/equipment/EquipmentContext";

const PlaygroundReportEquipmentsPageContent = () => {
    const { isEquipmentSelection } = usePlaygroundReportEquipmentsContext();
    return isEquipmentSelection ? (
        <EquipmentProvider>
            <ManufacturerProvider>
                <PlaygroundReportEquipmentsByManufacturerProvider>
                    <PlaygroundReportEquipmentsByManufacturer />
                </PlaygroundReportEquipmentsByManufacturerProvider>
            </ManufacturerProvider>
        </EquipmentProvider>
    ) : (
        <PlaygroundReportEquipmentsList />
    );
};

export default PlaygroundReportEquipmentsPageContent;
