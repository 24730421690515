import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DOCUMENT_TEMPLATE_TYPES } from "../../../utils/DocumentTemplateType";
import useAdminTemplateContext from "./useAdminTemplateContext";

function AdminTemplateTable() {
    const { templates, selectTemplate, download } = useAdminTemplateContext();

    const columns = [
        {
            field: "type",
            headerName: "Típus",
            minWidth: 70,
            flex: 0.3,
            valueGetter: (params) =>
                DOCUMENT_TEMPLATE_TYPES.find(
                    (value) => value.code === params.value
                ).name,
        },
        {
            field: "description",
            headerName: "Megjegyzés",
            minWidth: 130,
            flex: 0.6,
        },
        {
            field: "hasTemplate",
            headerName: "Sablon",
            minWidth: 130,
            flex: 0.1,
            renderCell: (params) =>
                params.value ? (
                    <strong>
                        <Button
                            size="small"
                            onClick={download}
                            tabIndex={params.hasFocus ? 0 : -1}
                        >
                            Letöltés
                        </Button>
                    </strong>
                ) : (
                    "Nincs"
                ),
        },
    ];

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        selectTemplate(id);
    }

    return (
        <div style={{ height: 400 }}>
            <DataGrid
                rows={templates}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default AdminTemplateTable;
