import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useCountyAuthorityContext from "./useCountyAuthorityContext";

function CountyAuthorityTable() {
    const { authorities, setSelectedAuthority } = useCountyAuthorityContext();

    const columns = [
        {
            field: "county",
            headerName: "Megye",
            minWidth: 130,
            flex: 0.1,
            valueGetter: (params) => params.row.county.name,
        },
        {
            field: "name",
            headerName: "Név",
            minWidth: 130,
            flex: 0.2,
        },
        {
            field: "address",
            headerName: "Cím",
            minWidth: 130,
            flex: 0.2,
        },
        {
            field: "administrator",
            headerName: "Ügyintéző",
            minWidth: 130,
            flex: 0.2,
        },
        {
            field: "emailAddresses",
            headerName: "Email címek",
            minWidth: 130,
            flex: 0.2,
        },
    ];

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedAuthority(id);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={authorities}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default CountyAuthorityTable;
