import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useCountyAuthorityContext from "./useCountyAuthorityContext";
import { Autocomplete } from "@mui/material";

function CountyAuthorityEditDialog() {
    const {
        data,
        counties,
        handleChange,
        showDialog,
        setShowDialog,
        save,
        errors,
        hasError,
    } = useCountyAuthorityContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title = data.id === null ? "Új hatóság" : "Hatóság szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Autocomplete
                    sx={{ width: "100%", my: 1 }}
                    id="county"
                    name="county"
                    value={data.county}
                    onChange={(event, newValue) => {
                        if (event != null) {
                            event.target.type = "autocomplete";
                            event.target.name = "county";
                            event.target.selectedOption = newValue;
                            handleChange(event);
                        }
                    }}
                    disabled={data.category != null}
                    options={counties}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Megye *" />
                    )}
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="name"
                    label="Név"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.name}
                    error={errors.name != null}
                    helperText={errors.name}
                    name="name"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="address"
                    label="Cím"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.address}
                    error={errors.address != null}
                    helperText={errors.address}
                    name="address"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="administrator"
                    label="Ügyintéző"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.administrator}
                    error={errors.administrator != null}
                    helperText={errors.administrator}
                    name="administrator"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="emailAddresses"
                    label="Email cím(ek) - vesszővel elválasztva"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.emailAddresses}
                    error={errors.emailAddresses}
                    helperText={errors.emailAddresses}
                    name="emailAddresses"
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CountyAuthorityEditDialog;
