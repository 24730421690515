import { PlaygroundReportEquipmentProvider } from "./PlaygroundReportEquipmentContext";
import PlaygroundReportEquipmentPageContent from "./PlaygroundReportEquipmentPageContent";

const PlaygroundReportEquipmentPage = () => {
    return (
        <PlaygroundReportEquipmentProvider>
            <PlaygroundReportEquipmentPageContent />
        </PlaygroundReportEquipmentProvider>
    );
};

export default PlaygroundReportEquipmentPage;
