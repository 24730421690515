export const ReportType = {
    PLAYGROUND: {
        code: "PLAYGROUND",
        urlparam: "playground",
    },
    SPORT_EQUIPMENT: {
        code: "SPORT_EQUIPMENT",
        urlparam: "sportequipment",
    },
};

export function getReportTypeByCode(code) {
    if (code === ReportType.PLAYGROUND.code) {
        return ReportType.PLAYGROUND;
    } else if (code === ReportType.SPORT_EQUIPMENT.code) {
        return ReportType.SPORT_EQUIPMENT;
    }
}

export function getReportTypeByUrlParam(urlparam) {
    if (urlparam === ReportType.PLAYGROUND.urlparam) {
        return ReportType.PLAYGROUND;
    } else if (urlparam === ReportType.SPORT_EQUIPMENT.urlparam) {
        return ReportType.SPORT_EQUIPMENT;
    }
}
