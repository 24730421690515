import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import EquipmentGroupEditDialog from "./EquipmentGroupEditDialog";
import EquipmentGroupTable from "./EquipmentGroupTable";
import useEquipmentGroupContext from "./useEquipmentGroupContext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "../../common/AlertDialog";
import PageTitleWithBackButton from "../../common/PageTitleWithBackButton";

function EquipmentGroupPageContent() {
    const {
        setShowDialog,
        setSelectedEquipmentGroup,
        showTableEditButtons,
        deleteEquipGroup,
        showDeleteConfirm,
        setShowDeleteConfirm,
    } = useEquipmentGroupContext();

    const handleClickOpen = (e) => {
        switch (e.target.name) {
            case "new": {
                setSelectedEquipmentGroup(null);
                setShowDialog(true);
                break;
            }
            case "edit": {
                setShowDialog(true);
                break;
            }
            case "delete": {
                setShowDeleteConfirm(true);
                break;
            }
            default:
                break;
        }
    };

    const handleDeleteConfirm = (positive) => {
        if (positive) {
            deleteEquipGroup();
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <PageTitleWithBackButton title="Eszköz csoportok" />
            <Stack direction="row" spacing={2} m={2}>
                <Button
                    variant="contained"
                    name="new"
                    onClick={handleClickOpen}
                >
                    Új eszköz csoport
                </Button>
                {showTableEditButtons && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="edit"
                            startIcon={<EditIcon />}
                            onClick={handleClickOpen}
                        >
                            Szerkesztés
                        </Button>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="delete"
                            startIcon={<DeleteIcon />}
                            onClick={handleClickOpen}
                        >
                            Törlés
                        </Button>
                    </Box>
                )}
            </Stack>
            <EquipmentGroupTable />
            <EquipmentGroupEditDialog />
            <AlertDialog
                open={[showDeleteConfirm, setShowDeleteConfirm]}
                message="Törlöd az eszköz csoportot?"
                positiveButton="Törlés"
                negativeButton="Mégse"
                buttonHandler={handleDeleteConfirm}
            />
        </Box>
    );
}

export default EquipmentGroupPageContent;
