import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { EQUIPMENT_TYPE_URL } from "./Url";

export function useEquipmentTypeApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getEquipmentTypes(onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${EQUIPMENT_TYPE_URL}/list`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function getEquipmentTypesByCategory(categoryId, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${EQUIPMENT_TYPE_URL}/list/${categoryId}`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveEquipmentType(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                id: data.id,
                name: data.name,
                description: data.description,
                category: data.category.id,
            };
            if (request.id === null) {
                await axiosPrivate
                    .post(`${EQUIPMENT_TYPE_URL}`, JSON.stringify(request), {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            } else {
                await axiosPrivate
                    .put(
                        `${EQUIPMENT_TYPE_URL}/${request.id}`,
                        JSON.stringify(request),
                        {
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true,
                        }
                    )
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function uploadEquipmentTypeCsv(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        const formData = new FormData();
        formData.append("File", data);

        try {
            await axiosPrivate
                .post(`${EQUIPMENT_TYPE_URL}/list`, formData, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteEquipmentType(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${EQUIPMENT_TYPE_URL}/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return {
        getEquipmentTypes,
        getEquipmentTypesByCategory,
        saveEquipmentType,
        deleteEquipmentType,
        uploadEquipmentTypeCsv,
    };
}
