import { CountyAuthorityProvider } from "./CountyAuthorityContext";
import CountyAuthorityPageContent from "./CountyAuthorityPageContent";

function CountyAuthorityPage() {
    return (
        <CountyAuthorityProvider>
            <CountyAuthorityPageContent />
        </CountyAuthorityProvider>
    );
}

export default CountyAuthorityPage;
