import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { useEquipmentTypeApi } from "../../../api/equipmentTypeApi";
import { useStandardApi } from "../../../api/standardApi";

const emptyStandard = {
    id: null,
    code: null,
    name: null,
    section: null,
    types: [],
    document: null,
};

const emptyErrors = {
    name: null,
};

const StandardContext = createContext({});

export const StandardProvider = ({ children }) => {
    const { getStandards, saveStandard, deleteStandard, downloadStandard } =
        useStandardApi();
    const { getEquipmentTypes } = useEquipmentTypeApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [standards, setStandards] = useState([]);
    const [equipmentTypes, setEquipmentTypes] = useState([]);
    const [errors, setErrors] = useState(emptyErrors);
    const [data, setData] = useState(emptyStandard);
    const hasError = !data.name || errors.name;
    const showTableEditButtons = data.id === null ? false : true;

    const handleChange = (e) => {
        const type = e.target.type;

        let name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                name = "document";
                value = e.target.files[0];
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchEquipmentTypes();
        fetchStandards();
    }, []);

    function setSelectedStandard(id) {
        if (id != null) {
            const standard = standards.find((standard) => standard.id === id);
            setData(standard);
        } else {
            setData(emptyStandard);
        }
    }

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    function fetchStandards() {
        getStandards((response) => {
            setStandards(response.data.standards);
        });
    }

    function fetchEquipmentTypes() {
        getEquipmentTypes((response) => {
            setEquipmentTypes(response.data.equipmentTypes);
        });
    }

    function save() {
        saveStandard(data, (response) => {
            fetchStandards();
            setShowDialog(false);
        });
    }

    function deleteStand() {
        deleteStandard(data.id, (response) => {
            fetchStandards();
        });
    }

    function download(id) {
        downloadStandard(id);
    }

    return (
        <StandardContext.Provider
            value={{
                data,
                equipmentTypes,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                standards,
                setStandards,
                setSelectedStandard,
                errors,
                hasError,
                handleChange,
                save,
                deleteStand,
                showTableEditButtons,
                download,
            }}
        >
            {children}
        </StandardContext.Provider>
    );
};

export default StandardContext;
