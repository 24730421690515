import { EquipmentTypeRestrictionProvider } from "./EquipmentTypeRestrictionContext";
import EquipmentTypeRestrictionPageContent from "./EquipmentTypeRestrictionPageContent";

function EquipmentTypeRestrictionPage() {
    return (
        <EquipmentTypeRestrictionProvider>
            <EquipmentTypeRestrictionPageContent />
        </EquipmentTypeRestrictionProvider>
    );
}

export default EquipmentTypeRestrictionPage;
