import { createContext, useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useGeneralErrorContext from "../../../common/generalerrorsnackbar/useGeneralErrorContext";
import { REPORT_URL, STANDARDISSUE_URL } from "../../../../api/Url";
import usePlaygroundReportEquipmentContext from "../usePlaygroundReportEquipmentContext";
import useReportContext from "../../useReportContext";
import useFullScreenProgressBarContext from "../../../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useReportImageContext from "../../useReportImagesContext";
import { useReportApi } from "../../../../api/reportApi";
import { useStandardApi } from "../../../../api/standardApi";
import { useStandardIssueApi } from "../../../../api/standardIssueApi";

const PlaygroundReportEquipmentIssuesContext = createContext({});

export const PlaygroundReportEquipmentIssuesProvider = ({ children }) => {
    const { fetchImages } = useReportImageContext();
    const { getStandardIssuesByEquipmentType } = useStandardIssueApi();
    const { getReportEquipmentIssues, deleteReportEquipmentIssue } =
        useReportApi();
    const { getStandardsByEquipmentType } = useStandardApi();
    const { setNextEnabled } = useReportContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const axiosPrivate = useAxiosPrivate();
    const { editedEquipment } = usePlaygroundReportEquipmentContext();

    const [reportEquipmentIssues, setReportEquipmentIssues] = useState([]);
    const [standards, setStandards] = useState([]);
    const [standardIssues, setStandardIssues] = useState([]);

    setNextEnabled(true);

    useEffect(() => {
        fetchReportEquipmentIssues();
        fetchStandardsByEquipmentType();
        fetchStandardIssuesByEquipmentType();
        fetchImages(true);
    }, [editedEquipment]);

    function fetchReportEquipmentIssues() {
        getReportEquipmentIssues(editedEquipment.id, (response) => {
            setReportEquipmentIssues(response.data.reportEquipmentIssues);
        });
    }

    function fetchStandardsByEquipmentType() {
        getStandardsByEquipmentType(
            editedEquipment.equipment.type.id,
            (response) => {
                setStandards(response.data.standards);
            }
        );
    }

    function fetchStandardIssuesByEquipmentType() {
        getStandardIssuesByEquipmentType(
            editedEquipment.equipment.type.id,
            (response) => {
                setStandardIssues(response.data.standardIssues);
            }
        );
    }

    const saveReportEquipmentIssue = async (params) => {
        let success = false;
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                standard: params.standard.id,
                standardSection: params.standardIssue.standardSection,
                description: params.standardIssue.description,
                image: params.image,
                dangerous: params.standardIssue.dangerous,
            };
            let response;
            if (isNaN(params.id)) {
                response = await axiosPrivate.post(
                    `${REPORT_URL}/reportequipment/${editedEquipment.id}/issue`,
                    JSON.stringify(request),
                    {
                        withCredentials: true,
                    }
                );
            } else {
                response = await axiosPrivate.put(
                    `${REPORT_URL}/reportequipment/${editedEquipment.id}/issue/${params.id}`,
                    JSON.stringify(request),
                    {
                        withCredentials: true,
                    }
                );
            }
            fetchReportEquipmentIssues();
            success = true;
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
        return success;
    };

    function deleteIssue(id) {
        deleteReportEquipmentIssue(id, (report) => {
            fetchReportEquipmentIssues();
        });
    }

    return (
        <PlaygroundReportEquipmentIssuesContext.Provider
            value={{
                standards,
                standardIssues,
                reportEquipmentIssues,
                saveReportEquipmentIssue,
                deleteIssue,
            }}
        >
            {children}
        </PlaygroundReportEquipmentIssuesContext.Provider>
    );
};

export default PlaygroundReportEquipmentIssuesContext;
