import { axiosPrivate } from "./axios";

const fetchCities = async (e) => {
    try {
        const citiesString = sessionStorage.getItem("cities");
        let cities;
        if (citiesString) {
            cities = JSON.parse(citiesString);
        } else {
            const response = await axiosPrivate.get(`city/list`, {
                withCredentials: true,
            });
            cities = response.data.cities;
            sessionStorage.setItem("cities", JSON.stringify(cities));
        }
        return cities;
    } catch (error) {
        return [];
    } finally {
    }
};

export default fetchCities;
