import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useEquipmentGroupContext from "./useEquipmentGroupContext";
import { Autocomplete, Stack } from "@mui/material";

function EquipmentGroupEditDialog() {
    const {
        data,
        equipments,
        equipmentCategories,
        manufacturers,
        selectedManufacturer,
        setSelectedManufacturer,
        handleEquipmentListChange,
        handleChange,
        showDialog,
        setShowDialog,
        save,
        errors,
        hasError,
    } = useEquipmentGroupContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title =
        data.id === null ? "Új eszköz csoport" : "Eszköz csoport szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Megnevezés"
                    multiline
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.name}
                    error={errors.name != null}
                    helperText={errors.name}
                    name="name"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Leírás"
                    multiline
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.description}
                    error={errors.description != null}
                    helperText={errors.description}
                    name="description"
                />
                <Autocomplete
                    sx={{ width: "100%", my: 1 }}
                    id="free-solo-demo"
                    name="categoryName"
                    value={data.category}
                    onChange={(event, newValue) => {
                        if (event != null) {
                            event.target.type = "autocomplete";
                            event.target.name = "category";
                            event.target.selectedOption = newValue;
                            handleChange(event);
                        }
                    }}
                    disabled={data.category != null}
                    options={equipmentCategories}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Eszköz kategória" />
                    )}
                />
                <Autocomplete
                    sx={{ width: "100%", my: 2 }}
                    id="free-solo-demo"
                    name="manufacturer"
                    value={selectedManufacturer}
                    onChange={(event, newValue) => {
                        if (event != null) {
                            setSelectedManufacturer(newValue);
                        }
                    }}
                    options={manufacturers}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Gyártó" />
                    )}
                />
                <Stack direction="row" spacing={2} sx={{ my: 2 }}>
                    <Autocomplete
                        sx={{ width: "100%" }}
                        id="free-solo-demo"
                        multiple
                        name="equipments"
                        value={data.equipments}
                        disabled={equipments.length === 0}
                        onChange={(event, newInputValue) => {
                            if (event != null) {
                                handleEquipmentListChange(
                                    "equipments",
                                    newInputValue
                                );
                            }
                        }}
                        options={equipments}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Eszközök" />
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EquipmentGroupEditDialog;
