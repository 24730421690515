import { Box, Button } from "@mui/material";
import useReportContext from "./useReportContext";

const PrevNextButtons = ({
    nextLabel = "Tovább",
    nextFunction,
    prevFunction,
}) => {
    const { activeStep, setActiveStep, isNextEnabled } = useReportContext();

    const handleNext = () => {
        nextFunction().then((response) => {
            if (response) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
            {activeStep === 0 ? (
                <Box></Box>
            ) : (
                <Button
                    color="inherit"
                    onClick={prevFunction ?? handleBack}
                    sx={{ mr: 1 }}
                >
                    Vissza
                </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleNext} disabled={!isNextEnabled}>
                {nextLabel}
            </Button>
        </Box>
    );
};

export default PrevNextButtons;
