import { Button, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PrevNextButtons from "../../PrevNextButtons";
import { EditType } from "../PlaygroundReportEquipmentContext";
import usePlaygroundReportEquipmentContext from "../usePlaygroundReportEquipmentContext";
import usePlaygroundReportEquipmentAttributesContext from "./usePlaygroundReportEquipmentAttributesContext";
import { useCallback, useEffect, useState } from "react";

function PlaygroundReportEquipmentAttributesTable() {
    const {
        equipmentTypeAttributes,
        reportEquipmentAttributes,
        showTableLoading,
        saveReportEquipmentAttributes,
    } = usePlaygroundReportEquipmentAttributesContext();

    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (equipmentTypeAttributes && reportEquipmentAttributes) {
            const rows = equipmentTypeAttributes.map((item) => {
                const reportEquipmentAttribute =
                    reportEquipmentAttributes?.find(
                        (reportItem) => reportItem.attribute.id === item.id
                    );

                return {
                    ...item,
                    value: reportEquipmentAttribute?.value ?? null,
                    attributeId: reportEquipmentAttribute?.id ?? null,
                };
            });
            setRows(rows);
        }
    }, [equipmentTypeAttributes, reportEquipmentAttributes]);

    const { setEditedId, setEditType, editNextEquipment, editedEquipment } =
        usePlaygroundReportEquipmentContext();

    const columns = [
        { field: "name", headerName: "Név", minWidth: 70, flex: 0.7 },
        {
            field: "value",
            headerName: "Érték",
            minWidth: 70,
            flex: 0.3,
            editable: true,
        },
        { field: "unit", headerName: "Mértékegység", minWidth: 70, flex: 0.3 },
    ];

    const navigateToEquipmentList = (id) => {
        setEditType(EditType.None);
    };

    const saveAndNextEquipment = (id) => {
        saveAttributes().then((success) => {
            if (success) {
                editNextEquipment(id);
            }
        });
    };

    const handleCellEditCommit = (editedRow, params) => {
        // You now have access to the entire row (editedRow) and the cell edit details (params)
        console.log("Edited Row:", editedRow);
        console.log("Edit Details:", params);
        const updatedRows = rows.map((row) => {
            if (row.id === params.id) {
                return { ...row, [params.field]: params.value };
            }
            return row;
        });
        setRows(updatedRows);
    };

    async function saveAttributes() {
        let values = new Map();
        if (rows) {
            rows.forEach((row) => {
                const reportEquipmentAttribute =
                    reportEquipmentAttributes?.find(
                        (item) => item.id === row.attributeId
                    );
                if (row.value) {
                    values.set(row.id, {
                        id: reportEquipmentAttribute?.id ?? null,
                        value: row.value,
                    });
                }
            });
        } else {
            values = {};
        }
        return saveReportEquipmentAttributes(values);
    }

    return (
        <div style={{ height: 500, width: "100%" }}>
            <Stack
                direction="row"
                spacing={2}
                m={2}
                justifyContent="space-between"
            >
                <Typography variant="h5">
                    {editedEquipment?.equipment?.name} adatai
                </Typography>
                <Button
                    variant="text"
                    name="new"
                    onClick={() => saveAndNextEquipment(editedEquipment.id)}
                >
                    Mentés és következő
                </Button>
            </Stack>
            <DataGrid
                rows={rows}
                loading={showTableLoading}
                columns={columns}
                pageSize={Math.min(100, equipmentTypeAttributes.length)}
                rowsPerPageOptions={[
                    Math.min(100, equipmentTypeAttributes.length),
                ]}
                onCellEditCommit={(params) => {
                    // Find the row that was edited
                    const editedRow = rows.find((row) => row.id === params.id);
                    // If the row is found, call handleCellEditCommit with the entire row and params
                    if (editedRow) {
                        handleCellEditCommit(editedRow, params);
                    }
                }}
                disableColumnSelector
            />

            <PrevNextButtons
                prevFunction={() => navigateToEquipmentList()}
                nextLabel="Mentés"
                nextFunction={() =>
                    saveAttributes().then((success) => {
                        if (success) {
                            navigateToEquipmentList();
                        }
                        return false;
                    })
                }
            />
        </div>
    );
}

export default PlaygroundReportEquipmentAttributesTable;
