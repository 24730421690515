import Dropzone from "react-dropzone";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";

const CustomDropzone = ({
    disabled,
    acceptedFileTypes,
    listFiles,
    message,
    onFileListChange,
}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const theme = useTheme();

    const dropzoneStyle = {
        border: "2px dashed #888",
        borderRadius: "4px",
        padding: "20px",
        textAlign: "center",
        cursor: "pointer",
    };

    const dragAcceptStyle = {
        border: `2px dashed ${theme.palette.primary.main}`,
        backgroundColor: "#f0f0f0",
        borderRadius: "4px",
        padding: "20px",
        textAlign: "center",
        cursor: "pointer",
    };

    const disabledStyle = {
        border: "2px dashed #999",
        borderRadius: "4px",
        padding: "20px",
        textAlign: "center",
        cursor: "default",
        color: "#999",
    };

    const handleDrop = (acceptedFiles) => {
        setSelectedFiles([...selectedFiles, ...acceptedFiles]);
        onFileListChange([...selectedFiles, ...acceptedFiles]);
    };

    const handleRemoveFile = (fileToRemove) => {
        const updatedFiles = selectedFiles.filter(
            (file) => file !== fileToRemove
        );
        setSelectedFiles(updatedFiles);
        onFileListChange(updatedFiles);
    };

    return (
        <Box>
            {listFiles && selectedFiles.length > 0 && (
                <div>
                    {selectedFiles.map((file, index) => (
                        <p key={index}>
                            <DeleteIcon
                                onClick={() => handleRemoveFile(file)}
                            />
                            {file.name}
                        </p>
                    ))}
                </div>
            )}
            <Dropzone
                accept={acceptedFileTypes}
                disabled={disabled}
                onDrop={handleDrop}
            >
                {({ getRootProps, getInputProps, isDragAccept }) => (
                    <div
                        {...getRootProps()}
                        style={
                            disabled
                                ? disabledStyle // Apply disabled style if disabled is true
                                : isDragAccept
                                ? dragAcceptStyle
                                : dropzoneStyle
                        }
                    >
                        <input {...getInputProps()} />
                        <p>{message}</p>
                    </div>
                )}
            </Dropzone>
        </Box>
    );
};

export default CustomDropzone;
