import { Card, CardActionArea, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ImageIcon from "@mui/icons-material/Image";
import { ROLE } from "../../utils/Roles";
import useAuth from "../../hooks/useAuth";

function AdminPage() {
    const navigate = useNavigate();
    const { auth } = useAuth();

    const handleUsersClick = (e) => {
        navigate(`/admin/users`);
    };

    const handleTemplatesClick = (e) => {
        navigate(`/admin/templates`);
    };

    const handleDropboxClick = (e) => {
        navigate(`/admin/dropboxauth`);
    };

    const handleTestPageClick = (e) => {
        navigate(`/admin/testpage`);
    };
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{ mt: 8 }}
        >
            <Card sx={{ width: 200, height: 200 }} onClick={handleUsersClick}>
                <CardActionArea sx={{ width: "100%", height: "100%" }}>
                    <Stack
                        display="column"
                        alignItems="center"
                        height="100%"
                        justifyContent="center"
                    >
                        <ManageAccountsIcon
                            sx={{ fontSize: 80 }}
                        ></ManageAccountsIcon>
                        <Typography variant="h5">Felhasználók</Typography>
                    </Stack>
                </CardActionArea>
            </Card>

            <Card
                sx={{ width: 200, height: 200 }}
                onClick={handleTemplatesClick}
            >
                <CardActionArea sx={{ width: "100%", height: "100%" }}>
                    <Stack
                        display="column"
                        alignItems="center"
                        height="100%"
                        justifyContent="center"
                    >
                        <ReceiptLongIcon
                            sx={{ fontSize: 80 }}
                        ></ReceiptLongIcon>
                        <Typography variant="h5">Sablonok</Typography>
                    </Stack>
                </CardActionArea>
            </Card>

            <Card sx={{ width: 200, height: 200 }} onClick={handleDropboxClick}>
                <CardActionArea sx={{ width: "100%", height: "100%" }}>
                    <Stack
                        display="column"
                        alignItems="center"
                        height="100%"
                        justifyContent="center"
                    >
                        <ImageIcon sx={{ fontSize: 80 }}></ImageIcon>
                        <Typography variant="h5">Dropbox</Typography>
                    </Stack>
                </CardActionArea>
            </Card>

            {ROLE.SUPERADMIN.code == auth?.role && (
                <Card
                    sx={{ width: 200, height: 200 }}
                    onClick={handleTestPageClick}
                >
                    <CardActionArea sx={{ width: "100%", height: "100%" }}>
                        <Stack
                            display="column"
                            alignItems="center"
                            height="100%"
                            justifyContent="center"
                        >
                            <ImageIcon sx={{ fontSize: 80 }}></ImageIcon>
                            <Typography variant="h5">Test</Typography>
                        </Stack>
                    </CardActionArea>
                </Card>
            )}
        </Grid>
    );
}

export default AdminPage;
