import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { useStandardApi } from "../../../api/standardApi";
import { useStandardIssueApi } from "../../../api/standardIssueApi";

const emptyStandardIssue = {
    id: null,
    standardSection: null,
    description: null,
    dangerous: false,
};

const StandardIssueContext = createContext({});

export const StandardIssueProvider = ({ children }) => {
    const { getStandards } = useStandardApi();
    const {
        getStandardIssuesByStandard,
        saveStandardIssue,
        deleteStandardIssue,
    } = useStandardIssueApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [standards, setStandards] = useState([]);
    const [selectedStandard, setSelectedStandard] = useState(null);

    const [standardIssues, setStandardIssues] = useState([]);

    const [standardIssue, setStandardIssue] = useState(emptyStandardIssue);

    const hasError =
        standardIssue.standardSection == null ||
        standardIssue.description == null;

    const showTableEditButtons = standardIssue.id === null ? false : true;

    function processData(name, value) {
        switch (name) {
            case "name": {
                break;
            }
            case "type":
                setSelectedStandard(value);
                break;
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        processData(e.target.name, value);

        setStandardIssue((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchStandards();
    }, []);

    function setSelectedStandardIssue(id) {
        if (id != null) {
            const standardIssue = standardIssues.find(
                (standardIssue) => standardIssue.id === id
            );
            setStandardIssue(standardIssue);
        } else {
            setStandardIssue(emptyStandardIssue);
        }
    }

    useEffect(() => {
        if (selectedStandard != null) {
            fetchStandardIssuesByStandard();
        } else {
            setStandardIssues([]);
        }
    }, [selectedStandard, setSelectedStandard]);

    function fetchStandards() {
        getStandards((response) => {
            setStandards(response.data.standards);
        });
    }

    function fetchStandardIssuesByStandard() {
        getStandardIssuesByStandard(selectedStandard.id, (response) => {
            setStandardIssues(response.data.standardIssues);
        });
    }

    function save() {
        saveStandardIssue(standardIssue, selectedStandard, (response) => {
            setStandardIssue(emptyStandardIssue);
            fetchStandardIssuesByStandard();
        });
    }

    function deleteStandardIssu() {
        deleteStandardIssue(standardIssue.id, (response) => {
            fetchStandardIssuesByStandard();
        });
    }

    return (
        <StandardIssueContext.Provider
            value={{
                standards,
                standardIssues,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                setSelectedStandardIssue,
                hasError,
                handleChange,
                selectedStandard,
                standardIssue,
                save,
                deleteStandardIssu,
                showTableEditButtons,
            }}
        >
            {children}
        </StandardIssueContext.Provider>
    );
};

export default StandardIssueContext;
