import { StandardProvider } from "./StandardContext";
import StandardPageContent from "./StandardPageContent";

function StandardPage() {
    return (
        <StandardProvider>
            <StandardPageContent />
        </StandardProvider>
    );
}

export default StandardPage;
