import { useEffect } from "react";
import getConfig from "../common/Config";

const DropBoxAuth = () => {
    const dropboxAppKey = getConfig("dropbox.appkey");
    const baseURL =
        process.env.NODE_ENV === "production"
            ? "https://www.playground-guard.hu"
            : "http://localhost:3000";
    const dropboxUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${dropboxAppKey}&response_type=code&token_access_type=offline&redirect_uri=${baseURL}/image/dropbox-code`;
    useEffect(() => {
        window.location.replace(dropboxUrl);
    }, []);

    // Render some text when redirecting
    // You can use a loading gif or something like that
    return (
        <div>
            <h3>Dropbox authorizacio...</h3>
        </div>
    );
};

export default DropBoxAuth;
